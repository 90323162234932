import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'


export default function CreateRole({ open, toggle, submitData, formData, handleChange }) {
    return (
        <>
            <Modal centered size='md' isOpen={open} toggle={toggle}>
                <ModalHeader toggle={toggle}>Create Role</ModalHeader>
                <form onSubmit={submitData}>
                    <ModalBody>
                        <div className='row'>
                            <div className='col-md-12'>
                                <label className='form-label mb-1'>Role Name <span className='text-danger'>*</span></label>
                                <input type='text' className='form-control' placeholder='Enter Role Name' value={formData?.name} name='name' required onChange={handleChange} />
                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <div className='d-flex justify-content-end gap-3'>
                            <button className='btn border' type='button' onClick={toggle}>Cancel</button>
                            <button className='btn btn-primary' type='submit'>Create</button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    )
}
