import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { IoClose } from "react-icons/io5";
import { useForm } from 'react-hook-form';

const JobTypeModel = ({ open, setOpen, submit }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    function toggle (){
        setOpen(!open)
    }
  return (
    <Modal size='sm' isOpen={open} centered >
    <ModalBody>
        <div className='d-flex justify-content-between align-items-center'>
            <div className='fw-600'>Select status</div>
            <IoClose className='fs-5 pointer' onClick={toggle}/>
        </div>
        <div className='pt-3'>
            {open?<form onSubmit={handleSubmit(data=>{(submit(data)); reset();})}>
                <div className='d-flex flex-column gap-3'>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value='PartTime' id="radio1" {...register('jobType', { required: true })} />
                        <label class="form-check-label" htmlFor="radio1">
                           Part Time
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value='FullTime' id="radio2" {...register('jobType', { required: true })} />
                        <label class="form-check-label" htmlFor="radio2">
                            Full Time
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value='Campaign' id="radio3" {...register('jobType', { required: true })} />
                        <label class="form-check-label" htmlFor="radio3">
                        Campaign
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value='Sale' id="radio4" {...register('jobType', { required: true })} />
                        <label class="form-check-label" htmlFor="radio4">
                            Sale
                        </label>
                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    <button className='btn btn-primary' type='submit'>Filter</button>
                </div>
            </form>:''}
        </div>
    </ModalBody>
</Modal>
  )
}

export default JobTypeModel
