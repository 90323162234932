import React, { useEffect, useState } from 'react'
import style from './style.module.css'
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { useNavigate } from 'react-router-dom';
import { FaUsersBetweenLines } from "react-icons/fa6";
import { MdOutlineFormatListBulleted } from "react-icons/md";
import { MdPayments } from "react-icons/md";
import { FaUsersCog } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import User from '../User/User';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosBusiness } from "react-icons/io";
import { HiUserGroup } from "react-icons/hi2";
import { BsDatabase } from "react-icons/bs";
import { MdFeaturedPlayList } from "react-icons/md";
import { IoIosNotifications } from "react-icons/io";
import { collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../Firebase/firebase';
import { SiMicrosoftaccess } from "react-icons/si";
import { getPermissionAccess, roleData } from '../../Utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { updatePermissionData } from '../../Store/Slices/PermissionSlice';


const drawerWidth = 260;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
}));

export default function Sidebar({ children }) {
    const matches = useMediaQuery('(min-width:1199px)');
    const [open, setOpen] = useState(true)
    const [toggle1, setToggle1] = useState(false)
    const navigate = useNavigate()
    const [notificationLength, setNotificationLength] = useState(0)
    const [roleDataList, setRoleDataList] = useState([])
    const selector = useSelector((e) => e?.permissionData?.permissions)
    const dispatch = useDispatch();
    console.log(selector)

    let location = window.location.href

    function activeRoute(res) {
        if (location.includes(res)) {
            return true
        } else {
            return false
        }
    }


    async function getNotifications() {
        try {
            const dataQuery = query(collection(db, 'webNotifications'), where('viewed', '==', false));
            onSnapshot(dataQuery, async (snapShot) => {
                let data = snapShot.docs.map((doc) => doc.data());
                setNotificationLength(data?.length)
            });

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getNotifications()
    }, [])

    useEffect(() => {
        if (matches) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [matches])


    async function getAccessData() {
        let token = localStorage.getItem('token')
        try {
            const docRef = doc(collection(db, 'role'), token);
            const docSnap = await getDoc(docRef);
            if (docSnap?.exists) {
                const userAccessData = docSnap.data();
                console.log(userAccessData?.permissions)
                setRoleDataList(userAccessData?.permissions || roleData)
                dispatch(updatePermissionData({ permissions: userAccessData?.permissions || roleData }))

            } else {
                setRoleDataList(roleData);
            }
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        getAccessData()
    }, [])

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <AppBar sx={{ background: 'white' }} position="fixed" open={!matches ? false : open}>
                    <Toolbar className='d-flex justify-content-between align-items-center'>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setOpen(!open)}
                            edge="start"
                            sx={{ mr: 2, mt: -1 }}
                        >
                            <i className="bi bi-list text-black fw-700"></i>
                        </IconButton>
                        <div className='d-flex gap-md-4 gap-sm-3 gap-2 align-items-center text-black mt-1' style={{ marginTop: '-10px' }}>
                            <User />
                            {getPermissionAccess("Notifications", selector, true) ?
                                <div className='notifier new' onClick={() => navigate('/notifications')}>
                                    <IoIosNotifications className='pointer bell fs-1' />
                                    {notificationLength !== 0 ? <div class="badge">{notificationLength}</div> : ''}
                                </div> : ''}
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant={!matches ? 'temporary' : "persistent"}
                    open={open} onClose={() => setOpen(!open)}>
                    <DrawerHeader>
                        <img src='/images/LinkEase.png' alt='' className='w-75' />
                    </DrawerHeader>
                    <Divider />
                    <List>
                        {/* <ListItem className='pb-0'>
                            <ListItemButton className={`text-black ${activeRoute('dashboard')?'activeTab':''} rounded`} onClick={()=>navigate('/dashboard')}>
                                <ListItemIcon>
                                    <MdSpaceDashboard className={`fs-5 ${activeRoute('dashboard')?'':'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={'Dashboard'} />
                            </ListItemButton>
                        </ListItem> */}
                        {getPermissionAccess("User Management Whole", selector, true) ? <>

                            <ListItem className='pb-0'>
                                <ListItemButton className={`text-black ${activeRoute('userManagement') ? 'activeTab' : ''} rounded`} onClick={() => setToggle1(!toggle1)}>
                                    <ListItemIcon>
                                        <FaUsersCog className={`fs-5 ${activeRoute('userManagement') ? '' : 'text-black'}  `} />
                                    </ListItemIcon>
                                    <ListItemText primary={<div className='w-100 d-flex align-items-center f-15'>User Management {toggle1 ? <IoIosArrowUp className='ms-2' /> : <IoIosArrowDown className='ms-2' />}</div>} />
                                </ListItemButton>
                            </ListItem>
                            <div className={`px-3 ps-5 ${toggle1 ? 'pt-2' : ''}`} style={{ height: toggle1 ? '160px' : '0px', overflow: 'hidden', transition: 'height 0.3s' }}>
                                <List className=''>
                                    {getPermissionAccess("Business", selector, true) ?
                                        <ListItemButton className={`text-black ${activeRoute('business') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/userManagement/business')}>
                                            <ListItemIcon>
                                                <IoIosBusiness className={`fs-5 ${activeRoute('business') ? '' : 'text-black'}  `} />
                                            </ListItemIcon>
                                            <ListItemText primary={<div className='f-15'>Business</div>} />
                                        </ListItemButton> : ''}
                                    {getPermissionAccess("Job Seeker", selector, true) ?
                                        <ListItemButton className={`text-black ${activeRoute('jobSeeker') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/userManagement/jobSeekers')}>
                                            <ListItemIcon>
                                                <HiUserGroup className={`fs-5 ${activeRoute('jobSeeker') ? '' : 'text-black'}  `} />
                                            </ListItemIcon>
                                            <ListItemText primary={<div className='f-15'>Job Seeker</div>} />
                                        </ListItemButton> : ''}
                                    {getPermissionAccess("Dashboard Users", selector, true) ?
                                        <ListItemButton className={`text-black ${activeRoute('dashboardUser') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/userManagement/dashboardUsers')}>
                                            <ListItemIcon>
                                                <FaUsersBetweenLines className={`fs-5 ${activeRoute('dashboardUser') ? '' : 'text-black'}  `} />
                                            </ListItemIcon>
                                            <ListItemText primary={<div className='f-15'>Users</div>} />
                                        </ListItemButton> : ''}
                                </List>
                            </div>
                        </> : ''}
                        {getPermissionAccess("Job Titles", selector, true) ?
                            <ListItem className='pb-0'>
                                <ListItemButton className={`text-black ${activeRoute('jobTitles') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/jobTitles')}>
                                    <ListItemIcon>
                                        <MdFeaturedPlayList className={`fs-5 ${activeRoute('jobTitles') ? '' : 'text-black'}  `} />
                                    </ListItemIcon>
                                    <ListItemText primary={<div className='f-15'>Job Titles</div>} />
                                </ListItemButton>
                            </ListItem> : ''}
                        {getPermissionAccess("Job Listing", selector, true) ?
                            <ListItem className='pb-0'>
                                <ListItemButton className={`text-black ${activeRoute('jobListing') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/jobListing')}>
                                    <ListItemIcon>
                                        <MdOutlineFormatListBulleted className={`fs-5 ${activeRoute('jobListing') ? '' : 'text-black'}  `} />
                                    </ListItemIcon>
                                    <ListItemText primary={<div className='f-15'>Job Listing</div>} />
                                </ListItemButton>
                            </ListItem> : ''}
                        {getPermissionAccess("Listing Fee", selector, true) ?
                            <ListItem className='pb-0'>
                                <ListItemButton className={`text-black ${activeRoute('feeManagement') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/feeManagement')}>
                                    <ListItemIcon>
                                        <BsDatabase className={`fs-5 ${activeRoute('feeManagement') ? '' : 'text-black'}  `} />
                                    </ListItemIcon>
                                    <ListItemText primary={<div className='f-15'>Listing Fee</div>} />
                                </ListItemButton>
                            </ListItem> : ''}
                        {getPermissionAccess("Payments", selector, true) ?
                            <ListItem className='pb-0'>
                                <ListItemButton className={`text-black ${activeRoute('payments') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/payments')}>
                                    <ListItemIcon>
                                        <MdPayments className={`fs-5 ${activeRoute('payments') ? '' : 'text-black'}  `} />
                                    </ListItemIcon>
                                    <ListItemText primary={<div className='f-15'>Payments</div>} />
                                </ListItemButton>
                            </ListItem> : ''}
                        {getPermissionAccess("Attendance Management", selector, true) ?
                            <ListItem className='pb-0'>
                                <ListItemButton className={`text-black ${activeRoute('attendance') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/attendance')}>
                                    <ListItemIcon>
                                        <FaUsersCog className={`fs-5 ${activeRoute('attendance') ? '' : 'text-black'}  `} />
                                    </ListItemIcon>
                                    <ListItemText primary={'Attendance Management'} />
                                </ListItemButton>
                            </ListItem> : ''}
                        {getPermissionAccess("System Management", selector, true) ?
                            <ListItem className='pb-0'>
                                <ListItemButton className={`text-black ${activeRoute('systemManagement') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/systemManagement')}>
                                    <ListItemIcon>
                                        <FiSettings className={`fs-5 ${activeRoute('systemManagement') ? '' : 'text-black'}  `} />
                                    </ListItemIcon>
                                    <ListItemText primary={<div className='f-15'>System Management</div>} />
                                </ListItemButton>
                            </ListItem> : ''}
                        {getPermissionAccess("Role Management", selector, true) ?
                            <ListItem className='pb-0'>
                                <ListItemButton className={`text-black ${activeRoute('roleManagement') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/roleManagement')}>
                                    <ListItemIcon>
                                        <SiMicrosoftaccess className={`fs-5 ${activeRoute('roleManagement') ? '' : 'text-black'}  `} />
                                    </ListItemIcon>
                                    <ListItemText primary={'Role Management'} />
                                </ListItemButton>
                            </ListItem> : ''}
                    </List>
                </Drawer>
                <Main open={!matches ? true : open}>
                    <div className={style.mainContainer}>
                        <div className={style.topbar}></div>
                        <div className={`${style.content} p-md-4 p-sm-3 p-2`}>
                            {children}
                        </div>
                    </div>
                </Main>
            </Box>
        </>
    )
}
