import './App.css';
import { Route, Routes } from 'react-router-dom';
import LoginAuth from './Auth/LoginAuth';
import Dashboard from './Pages/Dashboard/Dashboard';
import JobListing from './Pages/JobListing/JobListing';
import Payments from './Pages/Payments/Payments';
import SystemManagement from './Pages/SystemManagement/SystemManagement';
import Login from './Pages/Authentication/Login';
import Bussinesess from './Pages/UserManagement/Bussinesess';
import JobSeekers from './Pages/UserManagement/JobSeekers';
import ViewBusinessData from './Pages/UserManagement/BusinesessFiles/ViewBusinessData';
import ViewJobSeekerData from './Pages/UserManagement/JobSeekersFiles/ViewJobSeekerData';
import ViewListing from './Pages/JobListing/ViewListing';
import Notifications from './Pages/Notification/Notifications';
import FeeManagement from './Pages/FeeManagement/FeeManagement';
import ViewPayment from './Pages/Payments/ViewPayment';
import JobTitleListing from './Pages/JobTitleListing/JobTitleListing';
import CreateJobTitle from './Pages/JobTitleListing/CreateJobTitle';
import JobTitlesView from './Pages/JobTitleListing/JobTitlesView';
import AttendanceManagement from './Pages/AttendanceManagement/AttendanceManagement';
import ViewParticuler from './Pages/AttendanceManagement/ViewParticuler';
import { useEffect, useState } from 'react';
import { getNotification } from './Firebase/CloudNotification';
import { onMessage, getMessaging } from "firebase/messaging";
import Snakbar from './Compunents/Snackbar/Snakbar';
import DasboardUserListing from './Pages/RBACFilesolder/DasboardUserListing';
import RoleList from './Pages/RoleManagement/RoleList';
import ViewUsers from './Pages/RBACFilesolder/ViewUsers';
import UpdateAccess from './Pages/RoleManagement/UpdateAccess';



function App() {
  const [snackbar, showSnackbar] = useState({
    show: false,
    msg: "data added",
    type: "error",
  });
  useEffect(() => {
    getNotification()
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      showSnackbar({
        show: true,
        msg: "New notification received!",
        type: "success",
    })
    })

  }, [])




  return (
    <>
     <Snakbar data={snackbar} setData={showSnackbar} />
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<LoginAuth />}>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/userManagement/business' element={<Bussinesess />} />
          <Route path='/userManagement/business/view/:id' element={<ViewBusinessData />} />
          <Route path='/userManagement/jobSeekers' element={<JobSeekers />} />
          <Route path='/userManagement/jobSeekers/view/:id' element={<ViewJobSeekerData />} />
          <Route path='/userManagement/dashboardUsers' element={<DasboardUserListing />} />
          <Route path='/userManagement/dashboardUsers/view/:id' element={<ViewUsers />} />
          <Route path='/jobListing' element={<JobListing />} />
          <Route path='/roleManagement' element={<RoleList />} />
          <Route path='/roleManagement/access/:id' element={<UpdateAccess />} />
          <Route path='/jobListing/view/:id' element={<ViewListing />} />
          <Route path='/jobTitles' element={<JobTitleListing />} />
          <Route path='/jobTitles/create' element={<CreateJobTitle />} />
          <Route path='/jobTitles/view/:id' element={<JobTitlesView />} />
          <Route path='/payments' element={<Payments />} />
          <Route path='/payments/view/:id' element={<ViewPayment />} />
          <Route path='/feeManagement' element={<FeeManagement />} />
          <Route path='/systemManagement' element={<SystemManagement />} />
          <Route path='/attendance' element={<AttendanceManagement />} />
          <Route path='/attendance/view/:id' element={<ViewParticuler />} />
          <Route path='/notifications' element={<Notifications />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
