import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { IoClose } from "react-icons/io5";
import { useForm } from 'react-hook-form';

export default function StatusFilterModal({ open, setOpen, submit,forJobList }) {
    const { register, handleSubmit,reset, formState: { errors } } = useForm();
    const [value,setValue] = useState('')

    function toggle (){
        setOpen(!open)
    }

    function onSubmit(data) {
        submit(data); 
        reset(); 
    }

   
    return (
        <Modal size='sm' isOpen={open} centered >
            <ModalBody>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='fw-600'>Select status</div>
                    <IoClose className='fs-5 pointer' onClick={toggle}/>
                </div>
                <div className='pt-3'>
                    {open?<form onSubmit={handleSubmit(onSubmit)}>
                        {forJobList?<>
                            <div className='d-flex flex-column gap-3'>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value='active' id="radio1" {...register('status', { required: true })} />
                                <label class="form-check-label" htmlFor="radio1">
                                    Active
                                </label>
                            </div>
                           
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value='inactive'  id="radio3" {...register('status', { required: true })} />
                                <label class="form-check-label" htmlFor="radio3">
                                    Inactive
                                </label>
                            </div>
                        </div>
                        </>:<>
                        <div className='d-flex flex-column gap-3'>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value={true} id="radio1" {...register('status', { required: true })} />
                                <label class="form-check-label" htmlFor="radio1">
                                    Active
                                </label>
                            </div>
                           
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value={false}  id="radio3" {...register('status', { required: true })} />
                                <label class="form-check-label" htmlFor="radio3">
                                    Suspended
                                </label>
                            </div>
                        </div>
                        </>}
                        
                        <div className='d-flex justify-content-end'>
                            <button className='btn btn-primary' type='submit'>Filter</button>
                        </div>
                    </form>:''}
                </div>
            </ModalBody>
        </Modal>
    )
}
