import React, { useEffect, useState } from 'react'
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import TableContainer from '../../Compunents/TableContainer/TableContainer'
import { getMatchingData } from '../../Firebase/CloudFirestore/GetData'
import Loader from '../../Compunents/Loader/Loader'
import { changeDateFormate, getPermissionAccess, paginateData } from '../../Utils/Utils'
import Pagination from '../../Compunents/Pagination/Pagination'
import moment from 'moment'
import { FiFilter } from "react-icons/fi";
import DropDownMenu from '../../Compunents/DropDownMenu/DropDownMenu'
import StatusFilterModal from './StatusFilterModal'
import DateFilterModal from './DateFilterModal'
import { useNavigate } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import { updateDocument } from '../../Firebase/CloudFirestore/UpdateData'
import UserAvatar from '../../Compunents/User/UserAvatar'
import { useSelector } from 'react-redux'
import AccessDeniedUI from '../../Compunents/AccessDeniedUI/AccessDeniedUI'


export default function Bussinesess() {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [page, setPage] = useState(0);
  const rowsPerPage = 5
  const selector = useSelector((e) => e?.permissionData?.permissions)
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [allData2, setAllData2] = useState([])
  const [noData, setNoData] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [openDateFilterModal, setOpenDateFilterModal] = useState(false)

  const [statusChangeData, setStatusChangeData] = useState('')
  const [reasonForSuspend, setReasonForSuspend] = useState('')
  const [docId, setDocId] = useState('')
  const [openCommentModal, setopenCommentModal] = useState(false)
  const [search, setSearch] = useState('')



  async function getAllData() {
    setSearch('')
    try {
      let res = await getMatchingData('users', 'userType', '==', 'Business')
      if (res.length === 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
      setAllData(res)
      setAllData2(res)
      let slicedData = paginateData(page, rowsPerPage, res)
      setData(slicedData)

    } catch (err) {
      console.log(err)
    } finally {
      setLoader(false)
    }

  }

  useEffect(() => {
    getAllData()
  }, [])



  useEffect(() => {
    let slicedData = paginateData(page, rowsPerPage, allData)
    setData(slicedData)
  }, [page, rowsPerPage])



  function filterByStatus(data) {
    let arr = [...allData2]
    let newArr = arr?.filter(res => res?.adminStatus == JSON.parse(data?.status))
    setAllData(newArr)
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenFilterModal(false)
  }

  function filterByDate(data) {
    let arr = [...allData2]
    let newArr = arr?.filter(res => moment(changeDateFormate(res?.createdAt)).format('YYYY-MM-DD') == data?.date)
    setAllData(newArr)
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenDateFilterModal(false)
  }

  function clearFilter() {
    setAllData(allData2)
    let slicedData = paginateData(page, rowsPerPage, allData2)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }

  }

  function searchData(e) {
    let value = e?.toLowerCase()
    setSearch(e)
    let arr = [...allData2]
    if (e == '') {
      setAllData(allData2)
      let slicedData = paginateData(page, rowsPerPage, allData2)
      setData(slicedData)
      if (slicedData.length == 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
    } else {
      let newArr = arr?.filter(res => res?.owner_name?.toLowerCase()?.includes(value) || res?.businessName?.toLowerCase()?.includes(value))
      setAllData(newArr)
      let slicedData = paginateData(page, rowsPerPage, newArr)
      setData(slicedData)
      if (slicedData.length == 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
    }

  }


  async function changeStatus(e, id) {

    if (!JSON.parse(e)) {
      setDocId(id)
      setStatusChangeData(e)
      setopenCommentModal(true)
    } else {
      setLoader(true)
      try {
        await updateDocument('users', id, { adminStatus: JSON.parse(e) })

      } catch (err) {
        console.log(err)
      } finally {
        await getAllData()
        setLoader(false)
        setopenCommentModal(false)
        setDocId('')
        setReasonForSuspend('')
      }

    }

  }

  async function updateStatus() {
    setLoader(true)
    try {
      await updateDocument('users', docId, { adminStatus: JSON.parse(statusChangeData), suspensionReason: reasonForSuspend })

    } catch (err) {
      console.log(err)
    } finally {
      await getAllData()
      setLoader(false)
      setopenCommentModal(false)
      setDocId('')
      setReasonForSuspend('')
    }
  }


  return (
    <>
      <Modal size='md' centered isOpen={openCommentModal}>
        <ModalBody>
          <div className='fs-5 mb-2 fw-bold'>Enter Comment</div>
          <textarea rows='5' className='form-control mt-3' placeholder='Enter your comment here' value={reasonForSuspend} onChange={(e) => setReasonForSuspend(e.target.value)} />
          <div className='d-flex justify-content-end gap-3 mt-3'>
            <button className='btn btn-sm border' onClick={() => {
              setopenCommentModal(false)
              setStatusChangeData('')
              setReasonForSuspend('')
            }}>Cancel</button>
            <button className='btn btn-sm btn-primary' onClick={() => updateStatus()}>Submit</button>
          </div>
        </ModalBody>
      </Modal>
      <StatusFilterModal open={openFilterModal} setOpen={setOpenFilterModal} submit={filterByStatus} />
      <DateFilterModal open={openDateFilterModal} setOpen={setOpenDateFilterModal} submit={filterByDate} />
      <Loader open={loader} />
      {getPermissionAccess("Business", selector, true) ? <>
        <h2>Business</h2>
        <div className='row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3'>
          <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
            <input type='search' className='form-control w-100' value={search} placeholder='Search by business,owner name' onChange={(e) => {
              if (e.target.value == ' ') {
                e.target.value = ''
              } else {
                searchData(e.target.value)
              }
            }} />
          </div>
          <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2'>
            <DropDownMenu button={<button className='btn border'><FiFilter /> Filter by</button>}>
              <MenuItem onClick={() => setOpenFilterModal(true)}>Status</MenuItem>
              <MenuItem onClick={() => setOpenDateFilterModal(true)}>Registration date</MenuItem>
              <hr />
              <MenuItem onClick={() => clearFilter()}>Clear all filters</MenuItem>
            </DropDownMenu>
          </div>
        </div>

        <div className='mt-4'>
          <TableContainer>
            <Table>
              {noData ? <caption className='text-center'>No Data Found</caption> : ''}
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '2%' }}></TableCell>
                  <TableCell>Business Name</TableCell>
                  <TableCell>Owner Name</TableCell>
                  <TableCell>Industry</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Email</TableCell>
                  {/* <TableCell>Package</TableCell> */}
                  <TableCell>Registration Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((res, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell><UserAvatar name={res?.firstName} img={res?.profileImage} /></TableCell>
                      <TableCell className={`${getPermissionAccess("Business", selector, false, 'View Business') ? 'pointer' : ''} text-capitalize`} onClick={() => getPermissionAccess("Business", selector, false, 'View Business') ? navigate(`view/${res?.userId}`) : {}}>{res?.businessName}</TableCell>
                      <TableCell className='text-capitalize'>{res?.owner_name}</TableCell>
                      <TableCell title={res?.industry?.toString()}>{res?.industry?.toString().length > 30 ? <>{res?.industry?.toString()?.slice(0, 30)}...</> : res?.industry?.toString()}</TableCell>
                      <TableCell className='text-capitalize' >
                        {/* {res?.status} */}
                        {getPermissionAccess("Business", selector, false, 'Update status')?
                        <select className={`form-select bg-transparent f-14 px-1 ${res?.adminStatus ? 'text-success' : 'text-danger'}`} value={res?.adminStatus} onChange={(e) => changeStatus(e.target.value, res?.userId)}>
                          <option className='f-14 text-success' value={true}>Active</option>
                          <option className='f-14 text-danger' value={false}>Suspend</option>
                        </select>:res?.adminStatus?<div className='text-success'>Active</div>:<div className='text-danger'>Suspend</div>}
                      </TableCell>
                      <TableCell>{res?.phoneNumber}</TableCell>
                      <TableCell>{res?.email}</TableCell>
                      {/* <TableCell>{res?.package}</TableCell> */}
                      <TableCell>{moment(changeDateFormate(res?.createdAt)).format('DD MMM YYYY')}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>

            </Table>
            <Pagination
              allData={allData}
              currentPage={page}
              paginate={(d) => {
                setPage(d);
              }}
              itemPerPage={rowsPerPage}
            />

          </TableContainer>
        </div>
      </> : <>
        <AccessDeniedUI />
      </>}
    </>
  )
}

