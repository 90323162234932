import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { getCollectionData, getMatchingData } from '../../Firebase/CloudFirestore/GetData'

export default function CreateNewUser({ open, toggle, type, submitData, formData, setFormData, handleChange }) {
 const [roles,setRoles] = useState([])


    async function getAllRoles() {
        try {
            let data = await getMatchingData('role','name','!=','Super Admin')
            console.log(data)
            setRoles(data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (open) {
            getAllRoles()
        }
    }, [open])

    return (
        <>
            <Modal centered size='lg' isOpen={open} toggle={toggle}>
                <ModalHeader toggle={toggle}>{type} User</ModalHeader>
                <form onSubmit={submitData}>
                    <ModalBody>
                        <div className='row'>
                            <div className='col-md-12'>
                                <label className='form-label mb-1'>User Name <span className='text-danger'>*</span></label>
                                <input type='text' className='form-control' placeholder='Enter User Name' value={formData?.name} name='name' required onChange={handleChange} />
                            </div>
                            <div className='col-md-6 mt-3'>
                                <label className='form-label mb-1'>Phone Number <span className='text-danger'>*</span></label>
                                <input type='number' className='form-control' placeholder='Enter User Phone Number' value={formData?.phoneNumber} name='phoneNumber' required onChange={handleChange} />
                            </div>
                            <div className='col-md-6 mt-3'>
                                <label className='form-label mb-1'>Role <span className='text-danger'>*</span></label>
                                <select className='form-select' value={formData?.role} name='role' required onChange={handleChange}>
                                    <option disabled selected value=''>Select Role</option>
                                    {roles?.map((res,index)=><option key={index} value={res?.id}>{res?.name}</option>)}

                                </select>
                            </div>
                            <div className='col-md-6 mt-3'>
                                <label className='form-label mb-1'>Email Address <span className='text-danger'>*</span></label>
                                <input type='email' className='form-control' placeholder='Enter User Email Address' value={formData?.email} name='email' required onChange={handleChange} />
                            </div>
                            <div className='col-md-6 mt-3'>
                                <label className='form-label mb-1'>Password <span className='text-danger'>*</span></label>
                                <input type='text' className='form-control' placeholder='Enter Password' value={formData?.password} name='password' required onChange={handleChange} />
                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <div className='d-flex justify-content-end gap-3'>
                            <button className='btn border' type='button' onClick={toggle}>Cancel</button>
                            <button className='btn btn-primary' type='submit'>{type}</button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    )
}
