import { City, Country, State } from 'country-state-city';
import React, { useEffect, useState } from 'react'
import { FaRegEdit } from "react-icons/fa";
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import MultiSelectDropdown from '../../../Compunents/MultiSelectdropDown/MultiSelectDropdown';
import { updateDocument } from '../../../Firebase/CloudFirestore/UpdateData';
import Snakbar from '../../../Compunents/Snackbar/Snakbar';
import { useSelector } from 'react-redux';
import { getPermissionAccess } from '../../../Utils/Utils';

export default function ProfileEdit({ setLoader, getUserData, activeTab }) {
    let { id } = useParams()
    const selector = useSelector((e) => e?.permissionData?.permissions)

    const { handleSubmit } = useForm();
    const countries = Country?.getAllCountries()
    const [isEdit, setIsEdit] = useState(true)
    const [formData, setFormData] = useState({})
    const [formData2, setFormData2] = useState({})
    const [cities, setCities] = useState([])
    const [industry, setIndustry] = useState([])
    const [country, setCountry] = useState('')
    const [snackbar, showSnackbar] = useState({
        show: false,
        msg: "data added",
        type: "error",
    });

    const industries = [
        'Information Technology',
        'Graphics Design',
        'Healthcare',
        'Finance',
        'Education',
        'Manufacturing',
        'Retail',
        'Real Estate',
        'Transportation',
        'Construction',
        'Energy',
        'Telecommunications',
        'Hospitality',
        'Media',
        'Entertainment',
        'Consulting',
        'Automotive',
        'Agriculture',
        'Pharmaceutical',
        'Aerospace'
    ];



    async function getUserDataInner() {
        setLoader(true)
        try {
            let res = await getUserData()
            setFormData(res)
            setFormData2(res)
            setIndustry(res?.industry)
            let country = countries?.find(item => item.name === res?.country)
            setCountry(JSON.stringify(country))
            getAllCities(JSON.stringify(country))

        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (activeTab == 0) {
            getUserDataInner()
        }
    }, [activeTab])





    function getAllCities(res) {
        setCountry(res)
        let obj = JSON.parse(res)
        let cities = City.getCitiesOfCountry(obj?.isoCode)
        setCities(cities)
    }

    async function submitData(data) {
        let cc = JSON.parse(country)
        let payload = {
            ...formData,
            country: cc?.name,
            state: '',
            industry: industry
        }
        setLoader(true)
        try {
            await updateDocument('users', id, payload)
            showSnackbar({
                show: true,
                msg: "User data updated successfully",
                type: "success",
            })
        } catch (err) {
            console.log(err)
            showSnackbar({
                show: true,
                msg: "There are some problem please try again",
                type: "error",
            })
            return
        } finally {
            await getUserData()
            await getUserDataInner()
            setLoader(false)
            setIsEdit(true)
        }

    }

    function handleInput(e) {
        let { value, name } = e.target
        if (value === ' ') {
            e.target.value = ''
        } else {
            setFormData(pre => {
                return {
                    ...pre,
                    [name]: value
                }
            })
        }
    }


    return (
        <>
            <Snakbar data={snackbar} setData={showSnackbar} />
            <div className=''>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='fs-5 fw-600 text-secondary'>Profile Details</div>
                    {getPermissionAccess("Business", selector, false, 'Edit Business') ?
                        <button className='btn btn-primary d-flex align-items-center' onClick={() => {
                            setIsEdit(!isEdit)
                            setFormData(formData2)
                        }} >{isEdit ? <><FaRegEdit className='me-1' /> Edit</> : 'Cancel'}</button> : ''}
                </div>
                <form className='w-100' onSubmit={handleSubmit(submitData)}>
                    <div className='row mt-4'>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Business Owner</label>
                            <input className='form-control' placeholder='Enter business owner name' required disabled={isEdit} name='owner_name' value={formData?.owner_name} onChange={handleInput} />
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Business Name</label>
                            <input className='form-control' placeholder='Enter business name' required disabled={isEdit} name='businessName' value={formData?.businessName} onChange={handleInput} />
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Company Registration Number</label>
                            <input className='form-control' placeholder='Enter company registration number' required disabled={isEdit} name='companyRegistrationNumber' value={formData?.companyRegistrationNumber} onChange={handleInput} />
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Industry</label>
                            <MultiSelectDropdown options={industries} value={industry} setValue={setIndustry} disabled={isEdit} />
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Phone Number</label>
                            <input type='number' className='form-control' placeholder='Enter phone number' required disabled={isEdit} name='phoneNumber' value={formData?.phoneNumber} onChange={handleInput} onInput={(e) =>
                                (e.target.value = e.target.value.slice(0, 10))
                            } />
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Email Address</label>
                            <input type='email' className='form-control' placeholder='Enter email address' required disabled={isEdit} name='email' value={formData?.email} onChange={handleInput} />
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Country</label>
                            <select className='form-select' required disabled={isEdit} value={country} onChange={(e) => getAllCities(e.target.value)}>
                                <option value=''>Select Country</option>
                                {countries?.map((res, index) => <option key={index} value={JSON.stringify(res)}>{res?.name}</option>)}
                            </select>
                        </div>
                        {/* <div className='col-md-4 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>State</label>
                            <select className='form-select' required disabled={isEdit} value={state} onChange={(e) => getAllCities(e.target.value)}>
                                <option value=''>Select State</option>
                                {states?.map((res, index) => <option key={index} value={JSON.stringify(res)}>{res?.name}</option>)}
                            </select>
                        </div> */}
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>City</label>
                            <select className='form-select' required disabled={isEdit} value={formData?.city} name='city' onChange={handleInput} >
                                <option value=''>Select City</option>
                                {cities?.map((res, index) => <option key={index} value={res?.name}>{res?.name}</option>)}
                            </select>
                        </div>
                        <div className='col-md-12 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Post Code</label>
                            <input type='number' className='form-control' placeholder='Enter post code' required disabled={isEdit} name='postCode' value={formData?.postCode} onChange={handleInput} />
                        </div>
                        <div className='col-md-12 mt-3'>
                            <label className='form-label mb-1'>Address</label>
                            <textarea rows='4' className='form-control' placeholder='Enter full address' required disabled={isEdit} name='address' value={formData?.address} onChange={handleInput} />
                        </div>
                        {isEdit ? '' : <div className='d-flex justify-content-end gap-3 mt-4'>
                            {/* <button className='btn border'>Cancel</button> */}
                            <button className='btn btn-primary'>Submit</button>
                        </div>}
                    </div>
                </form>
            </div>
        </>
    )
}
