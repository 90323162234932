import React, { useEffect, useState } from 'react'
import Pagination from '../../Compunents/Pagination/Pagination'
import { MenuItem, TableHead, TableRow,Table, TableCell, TableBody } from '@mui/material'
import DropDownMenu from '../../Compunents/DropDownMenu/DropDownMenu'
import TableContainer from '../../Compunents/TableContainer/TableContainer'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { changeDateFormate, getPermissionAccess, paginateData } from '../../Utils/Utils'
import { FiFilter } from 'react-icons/fi'
import Loader from '../../Compunents/Loader/Loader'
import { Modal, ModalBody } from 'reactstrap'
import { getCollectionData, getMatchingData } from '../../Firebase/CloudFirestore/GetData'
import DateFilterModal from '../UserManagement/DateFilterModal'
import JobTypeModel from '../JobListing/JobTypeModel'
import AccessDeniedUI from '../../Compunents/AccessDeniedUI/AccessDeniedUI'
import { useSelector } from 'react-redux'
// import { Table } from 'reactstrap'

const FeeManagement = () => {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [page, setPage] = useState(0);
  const rowsPerPage = 5
  const selector = useSelector((e) => e?.permissionData?.permissions)

  const [data, setData] = useState([])
  const [allData, setAllData] = useState()
  const [noData, setNoData] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [openDateFilterModal, setOpenDateFilterModal] = useState(false)
  const [openJobTypeFilterModal, setOpenJobTypeFilterModal] = useState(false)

  const [statusChangeData, setStatusChangeData] = useState('')
  const [actionChangeData, setActionChangeData] = useState('')
  const [docId, setDocId] = useState('')
  
  const [openCommentModal, setopenCommentModal] = useState(false)
  const [data2,setData2] = useState([]); // State for filtered data

  useEffect(() => {
    let slicedData = paginateData(page, rowsPerPage, allData)
    setData(slicedData)
  }, [page, rowsPerPage])

  // function filterByJobType(data) {
  //   let arr = [...allData]
  //   let newArr = arr?.filter(res => res?.jobType == data?.jobType)
  //   console.log(data?.jobType);
  //   setPage(0);
  //   let slicedData = paginateData(0, rowsPerPage, newArr)
  //   setData(slicedData)
  //   // if (slicedData.length == 0) {
  //   if (newArr.length == 0) {
  //     setNoData(true)
  //   } else {
  //     setNoData(false)
  //   }
  //   setOpenJobTypeFilterModal(false)
  // }

  function filterByJobType(data) {
    let arr = [...data2];
    let newArr = arr?.filter(res => res?.jobType === data?.jobType);
    console.log(data?.jobType);
    
    setAllData(newArr)
    setPage(0);
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }

    setOpenJobTypeFilterModal(false);
}


  function filterByDate(data) {
    let arr = [...data2]
    let newArr = arr?.filter(res => moment(res?.data?.create_time).format('YYYY-MM-DD') == data?.date)
    setAllData(newArr)
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }

  setOpenDateFilterModal(false);
    
  }

  // function clearFilter() {
  //   let slicedData = paginateData(page, rowsPerPage, allData)
  //   setData(slicedData)
  //   if (slicedData.length == 0) {
  //     setNoData(true)
  //   } else {
  //     setNoData(false)
  //   }

  // }

  function clearFilter() {
    setAllData(data2)
    let slicedData = paginateData(page, rowsPerPage, data2)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }

  }


  function searchData(e) {
    let value = e?.toLowerCase()
    let arr = [...data2]
    console.log("Search Value:", value);
    console.log("All Data:", arr);
    if (e == '') {
      setAllData(data2)
      let slicedData = paginateData(page, rowsPerPage, data2)
      setData(slicedData)
    } else {
      let newArr = arr?.filter(res => 
        res?.jobTitle?.toLowerCase()?.includes(value) || 
        res?.jobType?.toLowerCase()?.includes(value) || 
        res?.paymentId?.toLowerCase()?.includes(value) || 
        res?.businessData?.businessName?.toLowerCase()?.includes(value))
        setAllData(newArr)
      let slicedData = paginateData(page, rowsPerPage, newArr)
      setData(slicedData)
      if (slicedData.length == 0) {
        setNoData(true)
      
      } else {
        setNoData(false)
        
      }
    }

  }

  async function getData(){
    setLoader();
    try{
        const [res, res1]= await Promise.all ([
          getCollectionData("payment"),
          getMatchingData('users', 'userType', '==', 'Business')

        ]);
        let newRes= res.map((item,index)=>{
          let userData= res1.find(item2=>item2.userId == item.userId)
          return{
            ...item,
            businessData: userData,
            sNo: index + 1
          }
        })

      
      setAllData(newRes);
      setData2(newRes)
      console.log(newRes)
        let slicedData= paginateData(page, rowsPerPage, newRes);
        setData(slicedData)
    }
    catch (err){
      console.log(err);
    }
    finally{
      setLoader(false);
    }
  }
  useEffect(() => {
    getData()
  }, [])



  function statusColor(key)
  {
    switch(key){
      case "success":
        return 'text-success'
        break;
      case "failure":
        return 'text-danger'
        break;
      default:
        break;     
    }
  }
  return (
    <>
      <Modal size='md' centered isOpen={openCommentModal}>
        <ModalBody>
          <div className='fs-5 mb-2 fw-bold'>FeedBack</div>
          <textarea rows='5' className='form-control mt-3' placeholder='Enter your comment here' />
          <div className='d-flex justify-content-end gap-3 mt-3'>
            <button className='btn btn-sm border' onClick={() => {
              setopenCommentModal(false)
              setStatusChangeData('')
            }}>Cancel</button>
            <button className='btn btn-sm btn-primary'
            //  onClick={() => updateStatus()} 
             >Submit</button>
          </div>
        </ModalBody>
      </Modal>
      {/* <StatusFilterModal open={openFilterModal} setOpen={setOpenFilterModal} submit={filterByStatus} /> */}
      <DateFilterModal open={openDateFilterModal} setOpen={setOpenDateFilterModal} submit={filterByDate}/>
      <JobTypeModel open={openJobTypeFilterModal} setOpen={setOpenJobTypeFilterModal} submit={filterByJobType} />
      {/* <Loader open={loader} /> */}
      {getPermissionAccess("Listing Fee", selector, true) ?<>
      <h2>Listing Fees</h2>
      <div className='row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3'>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
          <input type='search' className='form-control w-100' placeholder='Search by Job Title,Job Type, Business, Transaction ID' onChange={(e) => {
            if (e.target.value == ' ') {
              e.target.value = ''
              
            } else {
              searchData(e.target.value)
            }
          }} />
        </div>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2'>
          <DropDownMenu button={<button className='btn border'><FiFilter /> Filter by</button>}>
            {/* <MenuItem onClick={() => setOpenFilterModal(true)}>Status</MenuItem> */}
            <MenuItem onClick={() => setOpenDateFilterModal(true)}>Payment date</MenuItem>
            <MenuItem onClick={() => setOpenJobTypeFilterModal(true)}>Job Type</MenuItem>
            <hr />
            <MenuItem onClick={() => clearFilter()}>Clear all filters</MenuItem>
          </DropDownMenu>
        </div>
      </div>

      <div className='mt-4'>
        <TableContainer>
          <Table>
            {noData ? <caption className='text-center'>No Data Found</caption> : ''}
            <TableHead>
              <TableRow>
              <TableCell>#</TableCell>
                <TableCell>Business</TableCell>
                <TableCell>Job Title</TableCell>
                <TableCell>Job Type</TableCell>
                <TableCell>Fees Amount</TableCell>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Payment Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data ?.map((res, index) => {

                return (
                  <TableRow hover key={index} >
                    <TableCell className='text-capitalize'>{res?.sNo}</TableCell>
                    <TableCell className='text-capitalize'>{res?.businessData?.businessName}</TableCell>
                    {/* <TableCell className='pointer text-capitalize' onClick={() => navigate(`view/${res?.id}`)}>{res?.jobTitle}</TableCell> */}
                    <TableCell className='pointer text-capitalize'>{res?.jobTitle}</TableCell>

                    <TableCell>{res?.jobType}</TableCell>
                    <TableCell>{res?.data?.transactions.map(e=> e.amount.total)}{" "}USD</TableCell>
                    <TableCell>{res?.paymentId}</TableCell> 
                    <TableCell className={statusColor(res?.status)}>{res?.status}</TableCell>
                    <TableCell>{moment(res?.data?.create_time).format('DD-MM-YYYY')}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>

          </Table>
          {/* <Pagination
            allData={allData}
            currentPage={page}
            paginate={(d) => {
              setPage(d);
            }}
            itemPerPage={rowsPerPage}
          /> */}
          <Pagination
            allData={allData}
            currentPage={page}
            paginate={(d) => {
              setPage(d);
            }}
            itemPerPage={rowsPerPage}
          />

        </TableContainer>
      </div>
      </> : <>
       <AccessDeniedUI/>
      </>}
    </>
  )
}

export default FeeManagement
