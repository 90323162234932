import React, { useEffect, useState } from 'react'
import style from './style.module.css'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useMediaQuery } from '@mui/material';
import Loader from '../../Compunents/Loader/Loader';
import Snakbar from '../../Compunents/Snackbar/Snakbar';
import { IoIosArrowForward } from "react-icons/io";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate, useParams } from 'react-router-dom'
import Tab1 from './Tab1';
import Tab2 from './Tab2';
import { getDocumentData } from '../../Firebase/CloudFirestore/GetData';
import { changeDateFormate } from '../../Utils/Utils';


export default function ViewParticuler() {
    let { id } = useParams()
    const navigate = useNavigate()
    const matches = useMediaQuery('(min-width:768px)');
    const [loader, setLoader] = useState(false)
    const [activeTab, toggleTab] = useState('0')
    const [userData, setUserData] = useState({})
    const [snackbar, showSnackbar] = useState({
        show: false,
        msg: "data added",
        type: "error",
    });
    const [data, setData] = useState([])

    async function getData() {
        setLoader(true)
        try {
            const [data, users] = await Promise.all([
                getDocumentData('attendance', id),
                getDocumentData('users', id)
            ]);
            delete data.id
            //   console.log(users)
            setUserData(users)
            let extractedData = [];

            let allDates = [];

            for (let month in data) {
                for (let date in data[month]) {
                    allDates.push(data[month][date]);
                }
            }

            allDates.sort((a, b) => changeDateFormate(b.date) - changeDateFormate(a.date));

            extractedData = allDates;

            console.log(allDates);
            setData(extractedData)

        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        getData()

    }, [])

    return (
        <>
            <Loader open={loader} />
            <Snakbar data={snackbar} setData={showSnackbar} />
            <button className='btn ps-0 d-flex align-items-center' onClick={() => navigate('/attendance')}><IoArrowBack className='me-1' /> Back</button>
            <div className={`mt-4 ${style.tabContainer}`}>
                <div className={`${style.left}`}>
                    <Nav vertical={matches} className={`${!matches ? 'border-bottom d-flex flex-nowrap' : ''}`}>
                        <NavItem>
                            <NavLink
                                className={`text-secondary fw-bold ${activeTab == "0" ? !matches ? 'activeTab2' : 'activeTab text-white ' : ""} ${!matches ? 'px-2' : 'rounded ps-4 mt-2 py-2'} pointer`}
                                onClick={() => toggleTab("0")}>
                                <div className='d-flex justify-content-between align-items-center'><div>Presence Records</div> <IoIosArrowForward className='fw-600 d-md-block d-sm-none d-none' /></div>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={`text-secondary fw-bold ${activeTab == "1" ? !matches ? 'activeTab2' : 'activeTab text-white ' : ""} ${!matches ? 'px-2' : 'rounded ps-4 mt-2 py-2'} pointer`}
                                onClick={() => toggleTab("1")}>
                                <div className='d-flex justify-content-between align-items-center'><div>Prompt Records</div> <IoIosArrowForward className='fw-600 d-md-block d-sm-none d-none' /></div>
                            </NavLink>
                        </NavItem>


                    </Nav>

                </div>
                <div className={`${style.right}`}>
                    <TabContent className="px-md-3 px-sm-2 px-1 pt-md-4 pt-sm-4 pt-3" activeTab={activeTab}>
                        <TabPane tabId="0">
                            <Tab1 userData={userData} arr={data} />
                        </TabPane>
                        <TabPane tabId="1">
                            <Tab2 userData={userData} arr={data} />
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </>
    )
}
