
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import DropDownMenu from '../../Compunents/DropDownMenu/DropDownMenu';
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import TableContainer from '../../Compunents/TableContainer/TableContainer';
import Pagination from '../../Compunents/Pagination/Pagination';
import { changeDateFormate, getPermissionAccess, paginateData } from '../../Utils/Utils';
import moment from 'moment';
import { FiFilter } from 'react-icons/fi';
import { getCollectionData, getMatchingData } from '../../Firebase/CloudFirestore/GetData';
import UserAvatar from '../../Compunents/User/UserAvatar';
import MapDialog from './MapDialog';
import Loader from '../../Compunents/Loader/Loader';
import { FaBullseye } from 'react-icons/fa';
import AccessDeniedUI from '../../Compunents/AccessDeniedUI/AccessDeniedUI';
import { useSelector } from 'react-redux';

function AttendanceManagement() {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [page, setPage] = useState(0);
  const rowsPerPage = 5
  const selector = useSelector((e) => e?.permissionData?.permissions)

  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [allData2, setAllData2] = useState([])

  const [noData, setNoData] = useState(false)
  const [locationModal, setLocationModal] = useState(false)
  const [location, setLocation] = useState(null)
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [openDateFilterModal, setOpenDateFilterModal] = useState(false)
  const [openJobTypeFilterModal, setOpenJobTypeFilterModal] = useState(false)

  const [statusChangeData, setStatusChangeData] = useState('')
  const [actionChangeData, setActionChangeData] = useState('')
  const [docId, setDocId] = useState('')
  const [openCommentModal, setopenCommentModal] = useState(false)

  useEffect(() => {
    let slicedData = paginateData(page, rowsPerPage, allData)
    setData(slicedData)
  }, [page, rowsPerPage])

  function filterByStatus(data) {
    let arr = [...allData]
    let newArr = arr?.filter(res => res?.status == data?.status)
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenFilterModal(false)
  }
  function filterByJobType(data) {
    let arr = [...allData]
    let newArr = arr?.filter(res => res?.jobType == data?.jobType)
    // console.log(data?.jobType);
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenJobTypeFilterModal(false)
  }

  function filterByDate(data) {
    let arr = [...allData]
    let newArr = arr?.filter(res => moment(changeDateFormate(res?.jobPostedDate)).format('YYYY-MM-DD') == data?.date)
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenDateFilterModal(false)
  }

  function clearFilter() {
    let slicedData = paginateData(page, rowsPerPage, allData)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }

  }
  function searchData(e) {
    let value = e?.toLowerCase()
    let arr = [...allData2]
    if (e == '') {
      setAllData(allData2)
      let slicedData = paginateData(page, rowsPerPage, allData2)
      setData(slicedData)
    } else {
      let newArr = arr?.filter(res => res?.jobTitle?.toLowerCase()?.includes(value))
      let slicedData = paginateData(page, rowsPerPage, newArr)
      setData(slicedData)
      if (slicedData.length == 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
    }

  }


  async function getData() {
    setLoader(true)
    try {
      const [res, users] = await Promise.all([
        getCollectionData('attendance'),
        getMatchingData('users', 'userType', '==', 'Job Seeker')
      ]);
      let newRes = res?.map(((res, index) => {
        let userData = users?.find(item => item?.userId == res?.id)
        return {
          ...res,
          userData: userData,
          sNo: index + 1
        }
      }))
      // console.log(newRes)
      setAllData(newRes)
      let slicedData = paginateData(page, rowsPerPage, newRes)
      setData(slicedData)

    } catch (err) {
      console.log(err)
    } finally{
      setLoader(false)
    }
  }

  useEffect(() => {
    getData()
    
  }, [])

  function getCurrentMonth() {
    return moment().format('MMMM');
  }

  function getCurrentDate() {
    return moment().format('YYYY-MM-DD');
  }

  function getDate(data, type) {
    if (data[getCurrentMonth()] !== undefined) {
      if (data[getCurrentMonth()][getCurrentDate()] !== undefined) {
        let obj = data[getCurrentMonth()][getCurrentDate()]

        if (type == 'in') {
          return obj?.punchIn
        }
        else {
          return obj?.punchOut?obj?.punchOut:'--:--'
        }
      } else {
        return '--:--'
      }
    } else {
      return '--:--'
    }

  }
  function getLocation(data, type) {
    if (data[getCurrentMonth()] !== undefined) {
      if (data[getCurrentMonth()][getCurrentDate()] !== undefined) {
        let obj = data[getCurrentMonth()][getCurrentDate()]
        if (type == 'inLocation') {
          return obj?.punchInLocation? <span className='text-primary text-decoration-underline pointer' onClick={()=>{
            setLocation(obj?.punchInLocation)
            setLocationModal(true)
          }}>View Location</span>:'NA'
        }
        else if (type == 'outLocation') {
          return obj?.punchOutLocation ? <span className='text-primary text-decoration-underline pointer' onClick={()=>{
            setLocation(obj?.punchOutLocation)
            setLocationModal(true)
          }}>View Location</span>:'NA'
        }

      } else {
        return 'NA'
      }
    } else {
      return 'NA'
    }

  }

  function getAmPm(timeString) {
    if (timeString === "--:--" || timeString === null) {
      return '';
    }

    const [hours, minutes] = timeString?.split(':')?.map(Number);

    if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      return '';
    }

    return hours >= 12 ? 'PM' : 'AM';
  }







  return (
    <>
    <Loader open={loader}/>
      <MapDialog open={locationModal} toggle={() => {setLocationModal(false); setLocation(null)}} location={location} />
      <Modal size='md' centered isOpen={openCommentModal}>
        <ModalBody>
          <div className='fs-5 mb-2 fw-bold'>FeedBack</div>
          <textarea rows='5' className='form-control mt-3' placeholder='Enter your comment here' />
          <div className='d-flex justify-content-end gap-3 mt-3'>
            <button className='btn btn-sm border' onClick={() => {
              setopenCommentModal(false)
              setStatusChangeData('')
            }}>Cancel</button>
            <button className='btn btn-sm btn-primary'
            //  onClick={() => updateStatus()} 
            >Submit</button>
          </div>
        </ModalBody>
      </Modal>
      {/* <StatusFilterModal open={openFilterModal} setOpen={setOpenFilterModal} submit={filterByStatus} /> */}
      {/* <DateFilterModal open={openDateFilterModal} setOpen={setOpenDateFilterModal} submit={filterByDate} /> */}
      {/* <JobTypeModel open={openJobTypeFilterModal} setOpen={setOpenJobTypeFilterModal} submit={filterByJobType} /> */}
      {/* <Loader open={loader} /> */}
      {getPermissionAccess("Attendance Management", selector, true) ?<>
      <h2>Attendance Management</h2>
      <div className='row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3'>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
          <input type='search' className='form-control w-100' placeholder='Search' onChange={(e) => {
            if (e.target.value == ' ') {
              e.target.value = ''
            } else {
              searchData(e.target.value)
            }
          }} />
        </div>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2'>
          <DropDownMenu button={<button className='btn border'><FiFilter /> Filter by</button>}>
            <MenuItem onClick={() => setOpenFilterModal(true)}>Status</MenuItem>
            <MenuItem onClick={() => setOpenDateFilterModal(true)}>Created date</MenuItem>
            <MenuItem onClick={() => setOpenJobTypeFilterModal(true)}>Job Type</MenuItem>
            <hr />
            <MenuItem onClick={() => clearFilter()}>Clear all filters</MenuItem>
          </DropDownMenu>
        </div>
      </div>

      <div className='mt-4'>
        <TableContainer>
          <Table>
            {noData ? <caption className='text-center'>No Data Found</caption> : ''}
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Job Seeker Name</TableCell>
                <TableCell>Today’s Punch In</TableCell>
                <TableCell>Punch In Location</TableCell>
                <TableCell>Today’s Punch Out</TableCell>
                <TableCell>Punch Out Location</TableCell>
                <TableCell>Presence Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((res, index) => {

                return (
                  <TableRow hover key={index} >
                    <TableCell className='text-capitalize'>{res?.sNo}</TableCell>
                    <TableCell className={`${getPermissionAccess("Attendance Management", selector, false, 'View') ? 'pointer' : ''} text-capitalize`} onClick={() =>getPermissionAccess("Attendance Management", selector, false, 'View')? navigate(`view/${res?.id}`):{}}><div className='d-flex align-items-center gap-3'><UserAvatar img={res?.userData?.profileImage} name={res?.userData?.firstName} /> {`${res?.userData?.firstName} ${res?.userData?.lastName}`}</div></TableCell>
                    <TableCell className='text-capitalize'>{getDate(res, 'in')} {getAmPm(getDate(res, 'in'))}</TableCell>

                    <TableCell>{getLocation(res,'inLocation')}</TableCell>
                    <TableCell>{getDate(res, 'out')} {getAmPm(getDate(res, 'out'))}</TableCell>
                    <TableCell>{getLocation(res,'outLocation')}</TableCell>

                    <TableCell className='text-capitalize' >
                    {getDate(res, 'in') !== '--:--'?<div className='text-success'>Active</div>:<div className='text-danger'>Inactive</div>}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>

          </Table>
          <Pagination

            allData={allData}
            currentPage={page}
            paginate={(d) => {
              setPage(d);
            }}
            itemPerPage={rowsPerPage}
          />

        </TableContainer>
      </div>
      </> : <>
       <AccessDeniedUI/>
      </>}
    </>
  )
}

export default AttendanceManagement
