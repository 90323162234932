import React, { useEffect, useState } from 'react'
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import TableContainer from '../../Compunents/TableContainer/TableContainer'
import Loader from '../../Compunents/Loader/Loader'
import { changeDateFormate, getPermissionAccess, paginateData } from '../../Utils/Utils'
import Pagination from '../../Compunents/Pagination/Pagination'
import moment from 'moment'
import { FiFilter } from "react-icons/fi";
import DropDownMenu from '../../Compunents/DropDownMenu/DropDownMenu'
import { useNavigate } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import DateFilterModal from '../UserManagement/DateFilterModal'
import StatusFilterModal from '../UserManagement/StatusFilterModal'
import { getCollectionData, getMatchingData } from '../../Firebase/CloudFirestore/GetData'
import { updateDocument } from '../../Firebase/CloudFirestore/UpdateData'
import JobTypeModel from './JobTypeModel'
import AccessDeniedUI from '../../Compunents/AccessDeniedUI/AccessDeniedUI'
import { useSelector } from 'react-redux'

export default function JobListing() {

  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [page, setPage] = useState(0);
  const rowsPerPage = 5
  const selector = useSelector((e) => e?.permissionData?.permissions)

  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [allData2, setAllData2] = useState([])

  const [noData, setNoData] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [openDateFilterModal, setOpenDateFilterModal] = useState(false)
  const [openJobTypeFilterModal, setOpenJobTypeFilterModal] = useState(false)

  const [reasonForRejection, setResonForRejection] = useState('')
  const [actionChangeData, setActionChangeData] = useState('')
  const [docId, setDocId] = useState('')
  const [openCommentModal, setopenCommentModal] = useState(false)







  async function getListingData() {
    try {
      // let res = await getCollectionData('jobListing')
      const [res, users] = await Promise.all([
        getCollectionData('jobListing'),
        getMatchingData('users', 'userType', '==', 'Business')
      ]);
      let newRes = res?.map(item => {
        let user = users?.find(uu => uu?.userId == item.userId)
        return {
          ...item,
          createdBy: user?.businessName
        }
      })
      setAllData(newRes)
      setAllData2(newRes)
      let slicedData = paginateData(page, rowsPerPage, newRes)
      setData(slicedData)

    } catch (err) {
      console.log(err)
    } finally {
      setLoader(false)
    }

  }

  useEffect(() => {
    getListingData()
  }, [])
  useEffect(() => {
    let slicedData = paginateData(page, rowsPerPage, allData)
    setData(slicedData)
  }, [page, rowsPerPage])

  function filterByStatus(data) {
    let arr = [...allData2]
    let newArr = arr?.filter(res => res?.status == data?.status)
    setAllData(newArr)
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenFilterModal(false)
  }
  function filterByJobType(data) {
    let arr = [...allData2]
    let newArr = arr?.filter(res => res?.jobType == data?.jobType)
    // console.log(data?.jobType);
    setAllData(newArr)
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenJobTypeFilterModal(false)
  }

  function filterByDate(data) {
    let arr = [...allData2]
    let newArr = arr?.filter(res => moment(changeDateFormate(res?.jobPostedDate)).format('YYYY-MM-DD') == data?.date)
    setAllData(newArr)
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenDateFilterModal(false)
  }

  function clearFilter() {
    setAllData(allData2)
    let slicedData = paginateData(page, rowsPerPage, allData2)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }

  }
  function searchData(e) {
    let value = e?.toLowerCase()
    let arr = [...allData2]
    if (e == '') {
      setAllData(allData2)
      let slicedData = paginateData(page, rowsPerPage, allData2)
      setData(slicedData)
      if (slicedData.length == 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
    } else {
      let newArr = arr?.filter(res => res?.jobTitle?.toLowerCase()?.includes(value))
      setAllData(newArr)
      let slicedData = paginateData(page, rowsPerPage, newArr)
      setData(slicedData)
      if (slicedData.length == 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
    }

  }
  // Rejected, Approved, Under Review 

  async function changeStatus(e, id) {
    if (e == 'Rejected') {
      setDocId(id)
      setActionChangeData(e);
      setopenCommentModal(true)
    } else {
      setLoader(true)
      try {
        await updateDocument('jobListing', id, { jobStatus: e })

      } catch (err) {
        console.log(err)
      } finally {
        await getListingData()
        setLoader(false)
        setopenCommentModal(false)
        setDocId('')
        setResonForRejection('')
      }

    }

  }
  async function updateStatus() {
    setLoader(true)
    try {
      await updateDocument('jobListing', docId, { jobStatus: actionChangeData, rejectionReason: reasonForRejection })

    } catch (err) {
      console.log(err)
    } finally {
      await getListingData()
      setLoader(false)
      setopenCommentModal(false)
      setDocId('')
      setResonForRejection('')
    }
  }

  function statusChange(key) {
    switch (key) {
      case 'active':
        return 'text-success'
        break;
      case 'inactive':
        return 'text-danger'
        break;

      default:
        break;
    }
  }


  return (
    <>
      <Modal size='md' centered isOpen={openCommentModal}>
        <ModalBody>
          <div className='fs-5 mb-2 fw-bold'>FeedBack</div>
          <textarea rows='5' className='form-control mt-3' placeholder='Enter your comment here' value={reasonForRejection} onChange={(e) => setResonForRejection(e.target.value)} />
          <div className='d-flex justify-content-end gap-3 mt-3'>
            <button className='btn btn-sm border' onClick={() => {
              setopenCommentModal(false)
              setActionChangeData('')
              setResonForRejection('')
            }}>Cancel</button>
            <button className='btn btn-sm btn-primary' onClick={() => updateStatus()} >Submit</button>
          </div>
        </ModalBody>
      </Modal>
      <StatusFilterModal open={openFilterModal} setOpen={setOpenFilterModal} submit={filterByStatus} forJobList={true} />
      <DateFilterModal open={openDateFilterModal} setOpen={setOpenDateFilterModal} submit={filterByDate} />
      <JobTypeModel open={openJobTypeFilterModal} setOpen={setOpenJobTypeFilterModal} submit={filterByJobType} />
      <Loader open={loader} />
      {getPermissionAccess("Job Listing", selector, true) ? <>
        <h2>Job Listing</h2>
        <div className='row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3'>
          <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
            <input type='search' className='form-control w-100' placeholder='Search by job title' onChange={(e) => {
              if (e.target.value == ' ') {
                e.target.value = ''
              } else {
                searchData(e.target.value)
              }
            }} />
          </div>
          <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2'>
            <DropDownMenu button={<button className='btn border'><FiFilter /> Filter by</button>}>
              <MenuItem onClick={() => setOpenFilterModal(true)}>Status</MenuItem>
              <MenuItem onClick={() => setOpenDateFilterModal(true)}>Created date</MenuItem>
              <MenuItem onClick={() => setOpenJobTypeFilterModal(true)}>Job Type</MenuItem>
              <hr />
              <MenuItem onClick={() => clearFilter()}>Clear all filters</MenuItem>
            </DropDownMenu>
          </div>
        </div>

        <div className='mt-4'>
          <TableContainer>
            <Table>
              {noData ? <caption className='text-center'>No Data Found</caption> : ''}
              <TableHead>
                <TableRow>
                  <TableCell>Job Title</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Job Type</TableCell>
                  <TableCell>Year of Experince</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Posted date</TableCell>
                  <TableCell>Action </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((res, index) => {

                  return (
                    <TableRow hover key={index} >
                      <TableCell className={`${getPermissionAccess("Job Listing", selector, false, 'View') ? 'pointer' : ''} text-capitalize`} onClick={() => getPermissionAccess("Job Listing", selector, false, 'View') ? navigate(`view/${res?.id}`) : {}}>{res?.jobTitle}</TableCell>
                      <TableCell className='text-capitalize'>{res?.createdBy}</TableCell>
                      <TableCell className='text-capitalize'>{res?.jobLocation}</TableCell>

                      <TableCell>{res?.jobType}</TableCell>
                      <TableCell>{res?.yearOfExperience}</TableCell>
                      <TableCell className={`text-capitalize ${statusChange(res?.status)}`} >
                        {res?.status}
                      </TableCell>
                      <TableCell>{moment(changeDateFormate(res?.jobPostedDate)).format('DD MMM YYYY')}</TableCell>
                      <TableCell>
                        {getPermissionAccess("Job Listing", selector, false, 'Update status')?
                        <select className={`form-select bg-transparent f-14 px-1 ${res?.jobStatus == 'Approved' ? 'text-success' : res?.jobStatus == 'Rejected' ? 'text-danger' : 'text-warning'}`} value={res?.jobStatus} onChange={(e) => changeStatus(e.target.value, res?.id)}>
                          <option className='f-14 text-success' value={'Approved'}>Approved</option>
                          <option className='f-14 text-danger' value={'Rejected'}>Reject</option>
                          <option className='f-14' value='Under Review'>Under Review</option>
                        </select>:<div className={`${res?.jobStatus == 'Approved' ? 'text-success' : res?.jobStatus == 'Rejected' ? 'text-danger' : 'text-warning'}`}>${res?.jobStatus}</div>}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>

            </Table>
            <Pagination
              allData={allData}
              currentPage={page}
              paginate={(d) => {
                setPage(d);
              }}
              itemPerPage={rowsPerPage}
            />

          </TableContainer>
        </div>
      </> : <>
        <AccessDeniedUI />
      </>}
    </>
  )
}
