import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import DropDownMenu from '../../Compunents/DropDownMenu/DropDownMenu';
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import TableContainer from '../../Compunents/TableContainer/TableContainer';
import Pagination from '../../Compunents/Pagination/Pagination';
import { changeDateFormate, paginateData } from '../../Utils/Utils';
import moment from 'moment';
import { FiFilter } from 'react-icons/fi';
import UserAvatar from '../../Compunents/User/UserAvatar';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../Firebase/firebase';
import { updateDocument } from '../../Firebase/CloudFirestore/UpdateData';
import DateFilterModal from '../UserManagement/DateFilterModal';
import Loader from '../../Compunents/Loader/Loader';

function Notifications() {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [page, setPage] = useState(0);
  const rowsPerPage = 5
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])

  const [noData, setNoData] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [openDateFilterModal, setOpenDateFilterModal] = useState(false)
  const [openJobTypeFilterModal, setOpenJobTypeFilterModal] = useState(false)

  const [statusChangeData, setStatusChangeData] = useState('')
  const [actionChangeData, setActionChangeData] = useState('')
  const [docId, setDocId] = useState('')
  const [openCommentModal, setopenCommentModal] = useState(false)

  function filterByStatus(data) {
    let arr = [...allData]
    let newArr = arr?.filter(res => res?.status == data?.status)
    let slicedData = paginateData(page, rowsPerPage, newArr)
    // setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenFilterModal(false)
  }


  function filterByDate(data) {
    let arr = [...allData]
    let newArr = arr?.filter(res => moment(changeDateFormate(res?.createdAt)).format('yyyy-MM-DD') == data?.date)
    let newData = convertData(newArr)
    setData(newData)
    if (newArr.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenDateFilterModal(false)
  }

  function clearFilter() {
    let newData = convertData(allData)
    setData(newData)
    if (allData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }

  }

  async function getNotifications() {
    try {
      setLoader(true)
      const dataQuery = query(collection(db, 'webNotifications'));
      onSnapshot(dataQuery, async (snapShot) => {
        let data = snapShot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        let newData = convertData(data)
        setData(newData)
        setAllData(data)
      });

    } catch (err) {
      console.log(err)
    } finally{
      setLoader(false)
    }
  }


  async function markAsRead() {
    try {
      let data = [...allData]
      let filterData = data?.filter(res => !res?.viewed)
      if(filterData.length == 0){
        return
      }
      
      setLoader(true)
      let arr = filterData.map(async (res) => await updateDocument('webNotifications', res?.id, { viewed: true }))
      await Promise.all(arr)
      await getNotifications()
    } catch (err) {
      console.log(err)
    } finally{
      setLoader(false)
    }
  }

  function convertData(data) {
    const today = moment();
    const yesterday = moment().subtract(1, 'days');

    let convertedData = [
      { heading: 'Today', data: [] },
      { heading: 'Yesterday', data: [] },
      { heading: 'Older', data: [] }
    ];

    data.forEach(item => {
      const createdAt = moment.unix(item.createdAt.seconds); 

      if (createdAt.isSame(today, 'day')) {
        convertedData[0].data.push(item); 
      } else if (createdAt.isSame(yesterday, 'day')) {
        convertedData[1].data.push(item);
      } else {
        convertedData[2].data.push(item); 
      }
    });

    
    convertedData.forEach(section => {
      section.data.sort((a, b) => {
        return moment.unix(b.createdAt.seconds).valueOf() - moment.unix(a.createdAt.seconds).valueOf();
      });
    });

  

    return convertedData;
}



  useEffect(() => {
    getNotifications()
  }, [])

  function checkDate(date) {
    if (moment(changeDateFormate(date)).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')) {
      return `Today ${moment(changeDateFormate(date)).format('hh:mm a')}`
    } else {
      return moment(changeDateFormate(date)).format('DD-MM-YYYY hh:mm a')
    }

  }


  return (
    <>
    <Loader open={loader}/>
      <div className='d-flex justify-content-md-between justify-content-sm-between justify-content-md-start mt-3'>
        <h2>Notifications</h2>
        <div className='text-decoration-underline pointer' onClick={markAsRead}>Mark all as read</div>
      </div>
      <div className='d-flex justify-content-end'>
        <DropDownMenu button={<button className='btn border'><FiFilter /> Filter by</button>}>
          <MenuItem onClick={() => setOpenDateFilterModal(true)}>Date</MenuItem>
          <hr />
          <MenuItem onClick={() => clearFilter()}>Clear all filters</MenuItem>
        </DropDownMenu>
      </div>
      <DateFilterModal open={openDateFilterModal} setOpen={setOpenDateFilterModal} submit={filterByDate} />
      <div className='mt-4'>
        {data?.map((item, ind) => {
          return (
            <div key={ind}>
              <div className='fw-bold mt-4'>{item?.data?.length !== 0?item?.heading:''}</div>
              {item?.data?.length !== 0?item?.data?.map((res, index) => {
                return (
                  <div className='border rounded d-flex align-items-center p-2 mt-3' key={index}>
                    <UserAvatar img={res?.userProfile} name={res?.username} />
                    <div className='w-100 ms-2'>
                      <div>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className='fw-bold'>{res?.username}</div>
                          <div className='text-gray f-14 d-flex gap-2'>{checkDate(res?.createdAt)} {res?.viewed?'':<div className='unreadBandag'></div>}</div>
                        </div>
                        <div className='f-14'>
                          {res?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }):''}
            </div>
          )
        })}


      </div>
    </>
  )
}

export default Notifications
