import React, { useEffect, useState } from 'react'
import Pagination from '../../Compunents/Pagination/Pagination'
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import TableContainer from '../../Compunents/TableContainer/TableContainer'
import DropDownMenu from '../../Compunents/DropDownMenu/DropDownMenu'
import { useNavigate } from 'react-router-dom'
import { getPermissionAccess, paginateData } from '../../Utils/Utils'
import { FiFilter } from "react-icons/fi";
import { getCollectionData, getMatchingData } from '../../Firebase/CloudFirestore/GetData'
import Loader from '../../Compunents/Loader/Loader'
import AccessDeniedUI from '../../Compunents/AccessDeniedUI/AccessDeniedUI'
import { useSelector } from 'react-redux'


export default function JobTitleListing() {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(true)
    const [page, setPage] = useState(0);
    const rowsPerPage = 5
    const [data, setData] = useState([])
    const [allData, setAllData] = useState([])
    const [allData2, setAllData2] = useState([])
    const [noData, setNoData] = useState(false)
  const selector = useSelector((e) => e?.permissionData?.permissions)



    useEffect(() => {
        let slicedData = paginateData(page, rowsPerPage, allData)
        setData(slicedData)
    }, [page, rowsPerPage])


    useEffect(() => {
        getListingData()
    }, [])

    async function getListingData() {
        try {
            const [res, res2, res3] = await Promise.all([
                getCollectionData('jobTitles'),
                getCollectionData('jobListing'),
                getMatchingData('users', 'userType', '==', 'Job Seeker')
            ]);

            let newRes = res?.map((item, index) => {
                let jL = res2?.filter(ress => ress?.jobTitle == item.title)
                let jS = res3?.filter(ress => ress?.jobTitle == item.title)
                return {
                    ...item,
                    numberOfJobListing: jL?.length,
                    numberOfJobSeekers: jS?.length,
                    sNo: index + 1
                }
            })
            setAllData(newRes)
            setAllData2(newRes)
            let slicedData = paginateData(page, rowsPerPage, newRes)
            setData(slicedData)
            console.log(slicedData)

        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }

    }

    function searchData(e) {
        let value = e?.toLowerCase()
        let arr = [...allData2]
        if (e == '') {
            setAllData(allData2)
            let slicedData = paginateData(page, rowsPerPage, allData2)
            setData(slicedData)
            if (slicedData.length == 0) {
                setNoData(true)
            } else {
                setNoData(false)
            }
        } else {
            let newArr = arr?.filter(res => res?.title?.toLowerCase()?.includes(value))
            setAllData(newArr)
            let slicedData = paginateData(page, rowsPerPage, newArr)
            setData(slicedData)
            if (slicedData.length == 0) {
                setNoData(true)
            } else {
                setNoData(false)
            }
        }

    }


    return (
        <>
            <Loader open={loader} />
            {getPermissionAccess("Job Titles", selector, true) ?<>
            <div className='d-flex justify-content-md-between justify-content-sm-between justify-content-md-start mt-3'>
                <h2>Job Titles</h2>
                {getPermissionAccess("Job Titles", selector, false, 'Create')?
                <button className='btn btn-primary' onClick={() => navigate(`/jobTitles/create`)}>Create New</button>:''}
            </div>
            <div className='row justify-content-md-between justify-content-sm-between justify-content-md-start mt-4'>
                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
                    <input type='search' className='form-control w-100' placeholder='Search by job title' onChange={(e) => {
                        if (e.target.value == ' ') {
                            e.target.value = ''
                        } else {
                            searchData(e.target.value)
                        }
                    }} />
                </div>
                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2'>
                    {/* <DropDownMenu button={<button className='btn border'><FiFilter /> Filter by</button>}>
                        <MenuItem onClick={() => setOpenFilterModal(true)}>Status</MenuItem>
                        <MenuItem onClick={() => setOpenDateFilterModal(true)}>Created date</MenuItem>
                        <MenuItem onClick={() => setOpenJobTypeFilterModal(true)}>Job Type</MenuItem>
                        <hr />
                        <MenuItem onClick={() => clearFilter()}>Clear all filters</MenuItem>
                    </DropDownMenu> */}
                </div>
            </div>

            <div className='mt-4'>
                <TableContainer>
                    <Table>
                        {noData ? <caption className='text-center'>No Data Found</caption> : ''}
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Job Title</TableCell>
                                <TableCell>Listing Fees</TableCell>
                                <TableCell>Payrates for Business / hour</TableCell>
                                <TableCell>Salaries for Job Seekers / hour</TableCell>
                                <TableCell>No. of Job Listings</TableCell>
                                <TableCell>No. of Job Seekers</TableCell>
                              

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((res, index) => {

                                return (
                                    <TableRow hover key={index} >
                                        <TableCell className='text-capitalize'>{res?.sNo}</TableCell>
                                        <TableCell className={`${getPermissionAccess("Job Titles", selector, false, 'View') ? 'pointer' : ''} text-capitalize`} onClick={() =>getPermissionAccess("Job Titles", selector, false, 'View')? navigate(`view/${res?.id}`):{}}>{res?.title}</TableCell>
                                        <TableCell className='text-capitalize'>{res?.jobListingFee} USD</TableCell>
                                        <TableCell>{res?.payratesForBusiness} USD / person</TableCell>
                                        <TableCell>{res?.salariesForJobSeekers} USD / person</TableCell>
                                        <TableCell>{res?.numberOfJobListing}</TableCell>
                                        <TableCell>{res?.numberOfJobSeekers}</TableCell>
                                      
                                    </TableRow>
                                )
                            })}
                        </TableBody>

                    </Table>
                    <Pagination
                        allData={allData}
                        currentPage={page}
                        paginate={(d) => {
                            setPage(d);
                        }}
                        itemPerPage={rowsPerPage}
                    />

                </TableContainer>
            </div>
            </> : <>
       <AccessDeniedUI/>
      </>}
        </>
    )
}
