import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { updateDocument } from '../../Firebase/CloudFirestore/UpdateData';
import { getDocumentData } from '../../Firebase/CloudFirestore/GetData';
import Loader from '../../Compunents/Loader/Loader';
import Snakbar from '../../Compunents/Snackbar/Snakbar';
import { IoArrowBack } from "react-icons/io5";
import { roleData } from '../../Utils/Utils';



export default function UpdateAccess() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [permissions, setPermissions] = useState([])
  const [snackbar, showSnackbar] = useState({
    show: false,
    msg: "data added",
    type: "error",
  });

  let permissionData = roleData


  function moduleCheckboxChange(event, ind) {
    let checked = event.target.checked;
    setPermissions(pre => {
      pre[ind].isAcess = checked
      if (!checked) {
        pre[ind].sub_module = pre[ind].sub_module.map(ee => ({ ...ee, isAcess: false }))
      }
      return [...pre]
    })
  }

  function subModuleCheckboxChange(event, parentInd, cInd) {
    let checked = event.target.checked;
    setPermissions(pre => {
      if (checked) {
        pre[parentInd].isAcess = true
      }
      pre[parentInd].sub_module[cInd].isAcess = checked
      return [...pre]
    })
  }

  async function updateData() {
    try {
      setLoader(true)
      let payload = [...permissions]

      await updateDocument('role', id, { permissions: payload })
      showSnackbar({
        show: true,
        msg: "User access updated successfully",
        type: "success",
      })
    } catch (err) {
      console.log(err)
    } finally {
      await getData()
      setLoader(false)
    }
  }

  async function getData() {
    setLoader(true)
    try {
      let data = await getDocumentData('role', id)
      let per = data?.permissions || permissionData
      setPermissions(per)

    } catch (err) {
      console.log(err)
    } finally {
      setLoader(false)
    }
  }



  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Loader open={loader} />
      <Snakbar data={snackbar} setData={showSnackbar} />
      <button className='btn ps-0 d-flex align-items-center mb-3' onClick={() => navigate('/roleManagement')}><IoArrowBack className='me-1' /> Back</button>

      <h2>Update Access</h2>
      <div className='mt-4'>
        {permissions?.map((res, ind) => {
          return (
            <div key={ind}>

              <div className='row border rounded p-md-3 p-1 mb-3'>
                <div className="col-md-6 col-sm-12 col-12 d-flex justify-content-between align-items-center mb-2">
                  <div className='fw-bold'>
                    {res?.module}
                    {/* <div className='parentModule_description'>{res.description}</div> */}
                  </div>
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id={`flexSwitchCheckChecked${ind}`} onChange={(e) => moduleCheckboxChange(e, ind)} checked={res.isAcess} />
                    <label class="form-check-label" for={`flexSwitchCheckChecked${ind}`}></label>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='row p-0 m-0'>
                    <div className='col-md-6 col-sm-12 col-12'>
                      {res?.sub_module.map((res2, jj) => {
                        return (
                          <div key={jj} className="d-flex align-items-center justify-content-between">
                            <div className='childModule_heading mb-1'>{res2?.module}</div>
                            <div className='mb-1'>
                              <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id={`flexSwitchCheckChecked${jj}`} onChange={(e) => subModuleCheckboxChange(e, ind, jj)} checked={res2.isAcess} />
                                <label class="form-check-label" for={`flexSwitchCheckChecked${jj}`}></label>
                              </div>
                              {/* <input type='checkbox' className='form-check-input' onChange={(e) => subModuleCheckboxChange(e, ind, jj)} checked={res2.isAcess} /> */}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          )
        })}
      </div>
      <div className='w-100 d-flex justify-content-end gap-3 mt-3'>
        <button className='btn border'>Cancel</button>
        <button className='btn btn-primary' onClick={updateData}>Update</button>
      </div>


    </>
  )
}
