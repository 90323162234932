import React, { useEffect, useState } from 'react'
import DropDownMenu from '../../Compunents/DropDownMenu/DropDownMenu'
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import TableContainer from '../../Compunents/TableContainer/TableContainer'
import moment from 'moment'
import { changeDateFormate, getPermissionAccess, paginateData } from '../../Utils/Utils'
import { updateDocument } from '../../Firebase/CloudFirestore/UpdateData'
import { getCollectionData, getMatchingData } from '../../Firebase/CloudFirestore/GetData'
import { useNavigate } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import StatusFilterModal from '../UserManagement/StatusFilterModal'
import DateFilterModal from '../UserManagement/DateFilterModal'
import Loader from '../../Compunents/Loader/Loader'
import { FiFilter } from "react-icons/fi";
import Pagination from '../../Compunents/Pagination/Pagination'
import UserAvatar from '../../Compunents/User/UserAvatar'
import CreateNewUser from './CreateNewUser'
import { createDocument } from '../../Firebase/CloudFirestore/SetData'
import { emailPasswordSignUp } from '../../Firebase/FirebaseAuth/UserSignUp'
import { AiTwotoneDelete } from "react-icons/ai";
import AccessDeniedUI from '../../Compunents/AccessDeniedUI/AccessDeniedUI'
import { useSelector } from 'react-redux'




export default function DasboardUserListing() {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(true)
    const [page, setPage] = useState(0);
    const rowsPerPage = 5
    const selector = useSelector((e) => e?.permissionData?.permissions)
    const [data, setData] = useState([])
    const [allData, setAllData] = useState([])
    const [allData2, setAllData2] = useState([])
    const [noData, setNoData] = useState(false)
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const [openDateFilterModal, setOpenDateFilterModal] = useState(false)

    const [statusChangeData, setStatusChangeData] = useState('')
    const [reasonForSuspend, setReasonForSuspend] = useState('')
    const [docId, setDocId] = useState('')
    const [openCommentModal, setopenCommentModal] = useState(false)
    const [search, setSearch] = useState('')

    const [openDialog, setopenDialog] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        password: '',
        role: '',
        status: true

    })






    async function getAllData() {
        setSearch('')
        try {
            let [res, role] = await Promise.all([getMatchingData('dasboardUsers', 'email', '!=', 'admin@linkease.com'), getCollectionData('role')])
            let newData = res?.map((item) => {
                let rr = role?.find(e => e.id == item.role)
                return {
                    ...item,
                    roleName: rr?.name
                }
            })
            console.log(newData)
            if (newData.length === 0) {
                setNoData(true)
            } else {
                setNoData(false)
            }
            setAllData(newData)
            setAllData2(newData)
            let slicedData = paginateData(page, rowsPerPage, newData)
            setData(slicedData)

        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }

    }

    useEffect(() => {
        getAllData()
    }, [])


    useEffect(() => {
        let slicedData = paginateData(page, rowsPerPage, allData)
        setData(slicedData)
    }, [page, rowsPerPage])


    function filterByStatus(data) {
        let arr = [...allData2]
        let newArr = arr?.filter(res => res?.status == JSON.parse(data?.status))
        setAllData(newArr)
        let slicedData = paginateData(page, rowsPerPage, newArr)
        setData(slicedData)
        if (slicedData.length == 0) {
            setNoData(true)
        } else {
            setNoData(false)
        }
        setOpenFilterModal(false)
    }


    function filterByDate(data) {
        let arr = [...allData2]
        let newArr = arr?.filter(res => moment(changeDateFormate(res?.createdAt)).format('YYYY-MM-DD') == data?.date)
        setAllData(newArr)
        let slicedData = paginateData(page, rowsPerPage, newArr)
        setData(slicedData)
        if (slicedData.length == 0) {
            setNoData(true)
        } else {
            setNoData(false)
        }
        setOpenDateFilterModal(false)
    }



    function clearFilter() {
        setAllData(allData2)
        let slicedData = paginateData(page, rowsPerPage, allData2)
        setData(slicedData)
        if (slicedData.length == 0) {
            setNoData(true)
        } else {
            setNoData(false)
        }

    }

    function searchData(e) {
        let value = e?.toLowerCase()
        setSearch(e)
        let arr = [...allData2]
        if (e == '') {
            setAllData(allData2)
            let slicedData = paginateData(page, rowsPerPage, allData2)
            setData(slicedData)
            if (slicedData.length == 0) {
                setNoData(true)
            } else {
                setNoData(false)
            }
        } else {
            let newArr = arr?.filter(res => res?.name?.toLowerCase()?.includes(value))
            setAllData(newArr)
            let slicedData = paginateData(page, rowsPerPage, newArr)
            setData(slicedData)
            if (slicedData.length == 0) {
                setNoData(true)
            } else {
                setNoData(false)
            }
        }

    }



    async function changeStatus(e, id) {

        setLoader(true)
        try {
            await updateDocument('dasboardUsers', id, { status: JSON.parse(e) })

        } catch (err) {
            console.log(err)
        } finally {
            await getAllData()
            setLoader(false)
            setopenCommentModal(false)
            setDocId('')
            setReasonForSuspend('')
        }
    }


    async function updateStatus() {
        setLoader(true)
        try {
            await updateDocument('users', docId, { adminStatus: JSON.parse(statusChangeData), suspensionReason: reasonForSuspend })

        } catch (err) {
            console.log(err)
        } finally {
            await getAllData()
            setLoader(false)
            setopenCommentModal(false)
            setDocId('')
            setReasonForSuspend('')
        }
    }

    function toggle() {
        setopenDialog(false)
        setFormData({
            name: '',
            phoneNumber: '',
            email: '',
            password: '',
            role: '',
            status: true

        })
    }

    function handleChange(e) {
        let { name, value } = e.target
        if (e.target.value == ' ') {
            e.target.value = ''
        } else {
            setFormData(pre => {
                return {
                    ...pre,
                    [name]: value
                }
            })
        }
    }

    async function createNewUser(e) {
        e.preventDefault()
        setLoader(true)
        try {
            let payload = {
                ...formData,
                createdAt: new Date()
            }
            let user = await emailPasswordSignUp(payload?.name, payload?.email, payload?.password)
            let res = await createDocument('dasboardUsers', user?.uid, payload)
            toggle()
            await getAllData()

        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }

    return (
        <>
            <CreateNewUser
                open={openDialog}
                toggle={toggle}
                type={'Create'}
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
                submitData={createNewUser}
            />
            <Modal size='md' centered isOpen={openCommentModal}>
                <ModalBody>
                    <div className='fs-5 mb-2 fw-bold'>Enter Comment</div>
                    <textarea rows='5' className='form-control mt-3' placeholder='Enter your comment here' value={reasonForSuspend} onChange={(e) => setReasonForSuspend(e.target.value)} />
                    <div className='d-flex justify-content-end gap-3 mt-3'>
                        <button className='btn btn-sm border' onClick={() => {
                            setopenCommentModal(false)
                            setStatusChangeData('')
                            setReasonForSuspend('')
                        }}>Cancel</button>
                        <button className='btn btn-sm btn-primary' onClick={() => updateStatus()}>Submit</button>
                    </div>
                </ModalBody>
            </Modal>
            <StatusFilterModal open={openFilterModal} setOpen={setOpenFilterModal} submit={filterByStatus} />
            <DateFilterModal open={openDateFilterModal} setOpen={setOpenDateFilterModal} submit={filterByDate} />
            <Loader open={loader} />
            {getPermissionAccess("Dashboard Users", selector, true) ? <>
                <h2>Dashboard Users</h2>

                <div className='row justify-content-md-between justify-content-sm-between justify-content-start mt-3'>
                    <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
                        <input type='search' className='form-control w-100' value={search} placeholder='Search user by name' onChange={(e) => {
                            if (e.target.value == ' ') {
                                e.target.value = ''
                            } else {
                                searchData(e.target.value)
                            }
                        }} />
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2'>
                        <DropDownMenu button={<button className='btn border'><FiFilter /> Filter by</button>}>
                            <MenuItem onClick={() => setOpenFilterModal(true)}>Status</MenuItem>
                            <MenuItem onClick={() => setOpenDateFilterModal(true)}>Created date</MenuItem>
                            <hr />
                            <MenuItem onClick={() => clearFilter()}>Clear all filters</MenuItem>
                        </DropDownMenu>
                        {getPermissionAccess("Dashboard Users", selector, false, 'Create User')?
                        <button className='btn btn-sm btn-primary ms-3' onClick={() => setopenDialog(true)}>Create New</button>:''}
                    </div>
                </div>

                <div className='mt-4'>
                    <TableContainer>
                        <Table>
                            {noData ? <caption className='text-center'>No Data Found</caption> : ''}
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '2%' }}></TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Phone Number</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Created At</TableCell>
                                    {/* <TableCell></TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((res, index) => {
                                    return (
                                        <TableRow hover key={index}>
                                            <TableCell><UserAvatar name={res?.firstName} img={res?.profileImage} /></TableCell>
                                            <TableCell className={`${getPermissionAccess("Dashboard Users", selector, false, 'View User') ? 'pointer' : ''} text-capitalize`} onClick={() => getPermissionAccess("Dashboard Users", selector, false, 'View User') ? navigate(`view/${res?.id}`) : {}}>{res?.name}</TableCell>
                                            <TableCell className='text-capitalize'>{res?.roleName}</TableCell>
                                            <TableCell className='text-capitalize'>{res?.phoneNumber}</TableCell>
                                            <TableCell className=''>{res?.email}</TableCell>
                                            <TableCell className='text-capitalize' >
                                               {getPermissionAccess("Dashboard Users", selector, false, 'Update status')?
                                                <select className={`form-select bg-transparent f-14 px-1 ${res?.status ? 'text-success' : 'text-danger'}`} value={res?.status} onChange={(e) => changeStatus(e.target.value, res?.id)}>
                                                    <option className='f-14 text-success' value={true}>Active</option>
                                                    <option className='f-14 text-danger' value={false}>Deactivate</option>
                                                </select>:res?.status?<div className='text-success'>Active</div>:<div className='text-danger'>Deactivate</div>}
                                            </TableCell>
                                            <TableCell>{moment(changeDateFormate(res?.createdAt)).format('DD MMM YYYY')}</TableCell>
                                            {/* <TableCell>
                                        <AiTwotoneDelete className='pointer fs-5 text-primary'/>
                                        </TableCell> */}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>

                        </Table>
                        <Pagination
                            allData={allData}
                            currentPage={page}
                            paginate={(d) => {
                                setPage(d);
                            }}
                            itemPerPage={rowsPerPage}
                        />

                    </TableContainer>
                </div>
            </> : <>
                <AccessDeniedUI />
            </>}
        </>
    )
}
