import React, { useEffect, useState } from 'react'
import Pagination from '../../Compunents/Pagination/Pagination';
import moment from 'moment';
import { changeDateFormate, paginateData } from '../../Utils/Utils';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import MapDialog from './MapDialog';
import TableContainer from '../../Compunents/TableContainer/TableContainer';

export default function Tab2({ userData, arr }) {
    const [page, setPage] = useState(0);
    const rowsPerPage = 5
    const [data, setData] = useState([])
    const [allData, setAllData] = useState([])
    const [locationModal, setLocationModal] = useState(false)
    const [location, setLocation] = useState(null)

    useEffect(() => {
        let newData = formateData(arr) || []
        setAllData(newData)
        let slicedData = paginateData(page, rowsPerPage, newData)
        setData(slicedData)
    }, [page, rowsPerPage, arr])

    function formateData(data) {
        let array = addMissingDates(data)
        let newData = array?.map((res, index) => {
            let child = res?.activeTimes || []
            let newChild = child?.map(res2 => {
                return {
                    date: moment(changeDateFormate(res2?.timestamp)).format('hh:mm A'),
                    time: moment(changeDateFormate(res2?.timestamp)).format('hh:mm A'),
                    status: true,
                    location: res2?.location
                }
            })
            return {
                date: moment(changeDateFormate(res?.date)).format('DD-MM-YYYY'),
                children: newChild

            }
        })

        return newData?.reverse()

    }


    function addMissingDates(data) {
        data.sort((a, b) => a?.date?.seconds - b?.date?.seconds);

        const firstDate = moment(data[0]?.date?.seconds * 1000);
        const today = moment();

        const newData = [];
        let currentDate = moment(firstDate);

        while (currentDate.isSameOrBefore(today, 'day')) {
            const formattedDate = currentDate.format('YYYY-MM-DD');

            const existingEntry = data.find((entry) => {
                const entryDate = moment(entry.date.seconds * 1000).format('YYYY-MM-DD');
                return entryDate === formattedDate;
            });

            if (existingEntry) {
                newData.push(existingEntry);
            } else {
                newData.push({
                    date: { seconds: currentDate.unix(), nanoseconds: 0 },
                    punchIn: "--:--",
                    punchOut: "--:--",
                    totalHours: "0h 0m",
                    punchInLocation: 'NA',
                    punchOutLocation: "NA",
                    activeTimes: []

                });
            }

            currentDate.add(1, 'day');
        }

        return newData;
    }



    return (
        <>
            <MapDialog open={locationModal} toggle={() => { setLocationModal(false); setLocation(null) }} location={location} />

            <h2>{userData?.firstName} {userData?.lastName}</h2>
            <div className='mt-4'>
                <TableContainer>
                    <TreeTable className='customTable w-100' value={data} tableStyle={{ width: '100%', minWidth: '1000px' }}>
                        <Column style={{ width: '50px' }} expander ></Column>
                        <Column field="name" header="Date/Time" body={(rowData) => <span>{rowData?.date}</span>}></Column>
                        <Column field="size" header="Prompt Response Time" body={(rowData) => <span>{rowData?.time}</span>}></Column>
                        <Column field="type" header="Presence Status" body={(rowData) => rowData?.status !== undefined ? rowData?.status ? <div className='text-success'>Active</div> : <div className='text-danger'>Inactive</div> : ''}></Column>
                        <Column field="type" header="Punch In Location" body={(rowData) =>
                            rowData?.location ? <span className='text-primary text-decoration-underline pointer' onClick={() => {
                                setLocation(data)
                                setLocationModal(true)
                            }}>View Location</span> : ''
                        }></Column>
                    </TreeTable>
                    <Pagination

                        allData={allData}
                        currentPage={page}
                        paginate={(d) => {
                            setPage(d);
                        }}
                        itemPerPage={rowsPerPage}
                    />
                </TableContainer>

            </div>
        </>
    )
}
