import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IoArrowBack } from "react-icons/io5";
import style from './style.module.css'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useMediaQuery } from '@mui/material';
import { IoIosArrowForward } from "react-icons/io";
import { updateDocument } from '../../Firebase/CloudFirestore/UpdateData';
import Loader from '../../Compunents/Loader/Loader';
import { getCollectionData, getDocumentData, getMatchingData } from '../../Firebase/CloudFirestore/GetData';
import Snakbar from '../../Compunents/Snackbar/Snakbar';
import { getPermissionAccess } from '../../Utils/Utils';
import { useSelector } from 'react-redux';




export default function JobTitlesView() {
    let { id } = useParams()
    const navigate = useNavigate()
    const selector = useSelector((e) => e?.permissionData?.permissions)
    const [editData, setEditData] = useState(true)
    const [activeTab, toggleTab] = useState('0')
    const [jobData, setJobData] = useState(0)
    const [seekersData, setSeekersData] = useState(0)
    const matches = useMediaQuery('(min-width:768px)');
    const [loader, setLoader] = useState(false)
    const [backUpData, setBackUpData] = useState({})
    const [formData, setFormData] = useState({
        title: '',
        jobListingFee: '',
        payratesForBusiness: '',
        salariesForJobSeekers: ''
    })
    const [snackbar, showSnackbar] = useState({
        show: false,
        msg: "data added",
        type: "error",
    });

    function handleChange(e) {
        let { name, value } = e.target
        if (e.target.value == ' ') {
            e.target.value = ''
        } else {
            setFormData(pre => {
                return {
                    ...pre,
                    [name]: value
                }
            })

        }
    }

    useState(() => {
        getData()
    }, [])


    async function getData() {
        try {
            setLoader(true)
            const [data, users, jobs] = await Promise.all([
                getDocumentData('jobTitles', id),
                getMatchingData('users', 'userType', '==', 'Job Seeker'),
                getCollectionData('jobListing')
            ]);
            let jL = jobs?.filter(ress => ress?.jobTitle == data?.title)
            let jS = users?.filter(ress => ress?.jobTitle == data?.title)
            setJobData(jL?.length)
            setSeekersData(jS?.length)
            setBackUpData(data)
            setFormData(data)

        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }


    async function submitData(e) {
        e.preventDefault()
        try {
            setLoader(true)
            let payload = {
                ...formData,
            }
            delete payload.createdAt
            delete payload.id

            await updateDocument('jobTitles', id, payload)
            // navigate('/jobTitles')
            setEditData(true)
            // showSnackbar({
            //     show: true,
            //     msg: "Job Data Updated successfully",
            //     type: "success",
            // })
        } catch (err) {
            console.log(err)
            showSnackbar({
                show: true,
                msg: "Please check there are some error",
                type: "error",
            })
        } finally {
            setLoader(false)
        }
    }




    return (
        <>
            <Loader open={loader} />
            <Snakbar data={snackbar} setData={showSnackbar} />
            <button className='btn ps-0 d-flex align-items-center' onClick={() => navigate('/jobTitles')}><IoArrowBack className='me-1' /> Back</button>
            <div className={`mt-4 ${style.tabContainer}`}>
                <div className={`${style.left}`}>
                    <Nav vertical={matches} className={`${!matches ? 'border-bottom flex flex-nowrap' : ''}`}>
                        <NavItem>
                            <NavLink
                                className={`text-secondary fw-bold ${activeTab == "0" ? !matches ? 'activeTab2' : 'activeTab text-white ' : ""} ${!matches ? 'px-2' : 'rounded ps-4 mt-2 py-2'} pointer`}
                                onClick={() => toggleTab("0")}>
                                <div className='d-flex justify-content-between align-items-center'><div>Job Title Details</div> <IoIosArrowForward className='fw-600 d-md-block d-sm-none d-none' /></div>
                            </NavLink>
                        </NavItem>


                    </Nav>

                </div>
                <div className={`${style.right}`}>
                    <TabContent className="px-md-3 px-sm-2 px-1 pt-md-4 pt-sm-4 pt-3" activeTab={activeTab}>
                        <TabPane tabId="0">
                            <div className=''>
                                <div className='d-flex justify-content-between'>
                                    <h2 className=''>Job Title Details</h2>
                                    {getPermissionAccess("Job Titles", selector, false, 'Edit') ? editData ? <button className='btn btn-primary px-3' onClick={() => setEditData(false)}>Edit</button> : <button className='btn border px-3' onClick={() => { setEditData(true); setFormData(backUpData) }}>Cancel</button> : ''}

                                </div>
                                <form className='w-100' onSubmit={submitData}>
                                    <div className='row mt-4'>
                                        <div className='col-md-6'>
                                            <label className='form-label mb-1'>Job Title: <span className='text-danger'>*</span></label>
                                            <input type='text' required disabled={true} name='title' value={formData.title} onChange={handleChange} className='form-control' placeholder='Enter Job Title' />
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-md-4 col-sm-6 col-12 mt-3 d-flex flex-column'>
                                            <label className='form-label mb-1'>Job Listing Fees (USD): <span className='text-danger'>*</span></label>
                                            <input type='number' disabled={editData} required name='jobListingFee' value={formData.jobListingFee} onChange={handleChange} className='form-control mt-auto' placeholder='Enter Amount' />
                                        </div>
                                        <div className='col-md-4 col-sm-6 col-12 mt-3 d-flex flex-column'>
                                            <label className='form-label mb-1'>Payrates for Business / hour / person (USD): <span className='text-danger'>*</span></label>
                                            <input type='number' disabled={editData} required name='payratesForBusiness' value={formData.payratesForBusiness} onChange={handleChange} className='form-control mt-auto' placeholder='Enter Amount' />
                                        </div>
                                        <div className='col-md-4 col-sm-6 col-12 mt-3 d-flex flex-column'>
                                            <label className='form-label mb-1'>Salaries for Job Seekers / hour / person (USD): <span className='text-danger'>*</span></label>
                                            <input type='number' disabled={editData} required name='salariesForJobSeekers' value={formData.salariesForJobSeekers} onChange={handleChange} className='form-control mt-auto' placeholder='Enter Amount' />
                                        </div>
                                        <div className='col-md-6 col-sm-6 col-12 mt-3 d-flex flex-column'>
                                            <label className='form-label mb-1'>No. of Job Listings:</label>
                                            <input type='number' disabled={true} required value={jobData} onChange={handleChange} className='form-control mt-auto' placeholder='Enter Amount' />
                                        </div>
                                        <div className='col-md-6 col-sm-6 col-12 mt-3 d-flex flex-column'>
                                            <label className='form-label mb-1'>No. of Job Seekers:</label>
                                            <input type='number' disabled={true} required value={seekersData} onChange={handleChange} className='form-control mt-auto' placeholder='Enter Amount' />
                                        </div>
                                    </div>
                                    {editData ? '' :
                                        <div className='mt-4 w-100 d-flex justify-content-end'>
                                            <button className='btn btn-primary' type='submit'>Update Job Title</button>
                                        </div>}
                                </form>

                            </div>
                        </TabPane>


                    </TabContent>
                </div>
            </div>
        </>
    )
}
