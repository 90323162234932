import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase";
import { updateDocument } from "./CloudFirestore/UpdateData";



export async function getNotification() {
    const permission = await Notification.requestPermission()
    console.log(permission)
    if (permission === 'granted') {
        let token = await getToken(messaging, {
            vapidKey: 'BLl8cWaQKSWuXx7ivnvpisJVFfCG5Ibs-vSvTSLHES7TxInKOLDLfVIRzELunfATZCX8rS8XFLsky0t-Z4g2dMU'
        })
        console.log(token)
    }
}

export const getAdminFCMToken = async (adminId) => {
    try {
        let token = await getToken(messaging, {
            vapidKey: 'BLl8cWaQKSWuXx7ivnvpisJVFfCG5Ibs-vSvTSLHES7TxInKOLDLfVIRzELunfATZCX8rS8XFLsky0t-Z4g2dMU'
        })
        if (token) {
            let res = await updateDocument('dasboardUsers', adminId, { fcmToken: token })
        }
    } catch (error) {
        console.error('Error getting FCM token for admin:', error);
    }
};