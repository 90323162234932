import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { IoMdClose } from "react-icons/io";

export default function MapDialog({ open, toggle, location }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

   useEffect(()=>{
    if(open){
        setTimeout(()=>{
            setIsModalOpen(true)
        },300)
    }else{
        setIsModalOpen(false)
    }

   },[open])
    return (
        <>
            <Modal
                isOpen={open}
                centered
                size='xl'
                toggle={toggle}
                
            >
                <ModalBody>
                    <div className='d-flex justify-content-end py-1'>
                        <IoMdClose className='pointer fs-5' onClick={toggle} />
                    </div>
                    {isModalOpen && location?.latitude && location?.longitude ? <MapComponent latitude={location?.latitude} longitude={location?.longitude} /> : ''}

                </ModalBody>

            </Modal>

        </>
    )
}



const containerStyle = {
    width: '100%',
    height: '400px'
};

function MapComponent({ latitude, longitude }) {

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyCKodpnQoon5aC5csg7IXgFKD2gETOl0HA',
    });

    const center = {
        lat: latitude,
        lng: longitude,
    };

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
            >
                <Marker position={center} />
            </GoogleMap>
    );
}


