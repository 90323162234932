import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import TableContainer from '../../Compunents/TableContainer/TableContainer'
import DropDownMenu from '../../Compunents/DropDownMenu/DropDownMenu';
import { FiFilter } from 'react-icons/fi';
import Pagination from '../../Compunents/Pagination/Pagination';
import CustomDrawer from '../../Compunents/CustomDrawer/CustomDrawer';
import { changeDateFormate, getPermissionAccess, paginateData } from '../../Utils/Utils';
import moment from 'moment';
import DateFilterModal from '../UserManagement/DateFilterModal';
import { getCollectionData } from '../../Firebase/CloudFirestore/GetData';
import { updateDocument } from '../../Firebase/CloudFirestore/UpdateData';
import Loader from '../../Compunents/Loader/Loader';
import TransactionTypeModal from './TransactionTypeModal';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AccessDeniedUI from '../../Compunents/AccessDeniedUI/AccessDeniedUI';

export default function Payments() {
  const [userPermission, setUserPermission] = useState(['View Payment']);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const [loader, setLoader] = useState(false)
  const selector = useSelector((e) => e?.permissionData?.permissions)

  const [allData, setAllData] = useState();
  const [data, setData] = useState([]);

  const [noData, setNoData] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openDateFilterModal, setOpenDateFilterModal] = useState(false);
  const [TypeFilterModal, setTypeFilterModal] = useState(false);
  const [openJobTypeFilterModal, setOpenJobTypeFilterModal] = useState(false);
  const [statusChangeData, setStatusChangeData] = useState('');
  const [docId, setDocId] = useState('');
  const [openCommentModal, setopenCommentModal] = useState(false);
  const [rowmodal, setrowModal] = useState({ status: false, fullData: {} });

  const navigate= useNavigate();
  const rowtoggle = (data) => {
    if (userPermission.includes("View Payment")) {
      if (true) {
        setrowModal({ status: !rowmodal.status, fullData: data });
      }
    }
  };


  async function getListingData() {
    setLoader(true);
    try {
      let res = await getCollectionData('payment');
      
      console.log(res,"payment*");
      setAllData(res);
      let slicedData = paginateData(page, rowsPerPage, res);
      setData(slicedData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  }

  
  function changeStatus(e, id) {
    setDocId(id)
    setStatusChangeData(e)
    setopenCommentModal(true)

  }

  async function updateStatus() {
    setLoader(true)
    try {
      await updateDocument('payment', docId, { status: statusChangeData })

    } catch (err) {
      console.log(err)
    } finally {
      await getListingData()
      setLoader(false)
      setopenCommentModal(false)
      setDocId('')
    }
  }



  function searchData(e) {
    let value = e?.toLowerCase()
    let arr = [...allData]
    if (e == '') {
      let slicedData = paginateData(page, rowsPerPage, allData)
      setData(slicedData)
    } else {
      let newArr = arr?.filter(res => res?.data?.payer?.payer_info?.first_name?.toLowerCase()?.includes(value) ||  res?.data?.payer?.payer_info?.last_name?.toLowerCase()?.includes(value) || res?.data?.payer?.payment_method?.toLowerCase()?.includes(value))
      let slicedData = paginateData(page, rowsPerPage, newArr)
      setData(slicedData)
      if (slicedData.length == 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
    }

  }

  function filterByDate(data) {
    let arr = [...allData]
    const filteredData = arr.filter(res => moment(res?.data?.create_time).format('YYYY-MM-DD') == data?.date)
    let slicedData = paginateData(page, rowsPerPage, filteredData)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenDateFilterModal(false)
  }


  function filterByTransactionType(data) {
    let arr = [...allData]
    const filteredData = arr.filter(res =>
      res?.data?.payer?.payment_method === data.transactionType
    );
    console.log(data.transactionType)
    let slicedData = paginateData(page, rowsPerPage, filteredData)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setTypeFilterModal(false)
  }

  function clearFilter() {
    setLoader(true)
    let slicedData = paginateData(page, rowsPerPage, allData)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setLoader(false)

  }
  useEffect(() => {
    getListingData()
  }, [])

  function statusColor(key)
  {
    switch(key){
      case "success":
        return 'text-success'
        break;
      case "failure":
        return 'text-danger'
        break;
      default:
        break;     
    }
  }


  return (
    <>
      <Modal size='md' centered isOpen={openCommentModal}>
        <ModalBody>
          <div className='fs-5 mb-2 fw-bold'>Enter Comment</div>
          <textarea rows='5' className='form-control mt-3' placeholder='Enter your comment here' />
          <div className='d-flex justify-content-end gap-3 mt-3'>
            <button className='btn btn-sm border' onClick={() => {
              setopenCommentModal(false);
              setStatusChangeData('');
            }}>Cancel</button>
            <button className='btn btn-sm btn-primary ' onClick={() => updateStatus()}>Submit</button>
          </div>
        </ModalBody>
      </Modal>
      <DateFilterModal open={openDateFilterModal} setOpen={setOpenDateFilterModal} submit={filterByDate} />
      <TransactionTypeModal open={TypeFilterModal} setOpen={setTypeFilterModal} submit={filterByTransactionType} />
      <Loader open={loader} />
      {getPermissionAccess("Payments", selector, true) ?<>
      <h2>Payment</h2>
      <div className='row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3'>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
          <input type='search' className='form-control w-100' placeholder='Search by name, transaction type ' onChange={(e) => {
            if (e.target.value == ' ') {
              e.target.value = ''
            } else {
              searchData(e.target.value)
            }
          }} />
        </div>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2'>
          <DropDownMenu button={<button className='btn border'><FiFilter /> Filter by</button>}>
            <MenuItem onClick={() => setOpenDateFilterModal(true)}>Date</MenuItem>
            {/* <MenuItem >User</MenuItem> */}
            <MenuItem onClick={() => setTypeFilterModal(true)}>Transaction Type</MenuItem>
            <hr />
            <MenuItem onClick={() => clearFilter()}>Clear all filters</MenuItem>
          </DropDownMenu>
        </div>
      </div>

      <div className='mt-4'>
        <TableContainer>
          <Table>
            {noData ? <caption className='text-center'>No Data Found</caption> : ''}
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Transaction Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date/Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((res, index) => {
                return (
                  <TableRow hover key={index} >
                    <TableCell
                      className={`text-nowrap text-capitalize ${getPermissionAccess("Payments", selector, false, 'View') ? "pointer" : ""}`}
                      onClick={() =>getPermissionAccess("Payments", selector, false, 'View')? navigate(`view/${res?.id}`):{}}
                    >
                      {res?.data?.payer?.payer_info?.first_name} {" "}{res?.data?.payer?.payer_info?.last_name}
                    </TableCell>
                    <TableCell>{res?.data?.transactions.map(e=> e.amount.total)}</TableCell>
                    <TableCell>{res?.paymentId}</TableCell>
                    <TableCell>{res?.data?.payer?.payment_method}</TableCell>             
                    <TableCell className={statusColor(res?.status)}>{res?.status}</TableCell>
                    <TableCell>{moment(allData?.data?.create_time).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <Pagination
            allData={allData}
            currentPage={page}
            paginate={(d) => setPage(d)}
            itemPerPage={rowsPerPage}
          />
        </TableContainer>
      </div>
      </> : <>
       <AccessDeniedUI/>
      </>}
    </>
  );
}
