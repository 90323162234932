import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getMatchingData } from '../../../Firebase/CloudFirestore/GetData'
import style from './style.module.css'
import DocumentCard from './DocumentCard'
import AddDocumentModal from './AddDocumentModal'
import { updateDocument } from '../../../Firebase/CloudFirestore/UpdateData'
import { deleteImage, uploadFile } from '../../../Firebase/CloudStorage/UploadImages'
import ViewFile from './ViewFile'
import Snakbar from '../../../Compunents/Snackbar/Snakbar'
import StatusChangeModal from './StatusChangeModal'
import { addDocument } from '../../../Firebase/CloudFirestore/SetData'
import { deleteDocument } from '../../../Firebase/CloudFirestore/DeleteData'
import DeleteModal from '../../../Compunents/DeleteModal/DeleteModal'
import { useSelector } from 'react-redux'
import { getPermissionAccess } from '../../../Utils/Utils'

export default function DocumentsEdit({ setLoader, activeTab }) {
    let { id } = useParams()
    const [allDocuments, setAllDocuments] = useState([])
    const [openAddModal, setOpenAddModal] = useState(false)
    const [selectStatus, setSelectStatus] = useState('')
    const [file, setFile] = useState(null)
    const selector = useSelector((e) => e?.permissionData?.permissions)


    const [viewData, setViewData] = useState({})
    const [docId, setDocId] = useState('')
    const [openView, setOpenView] = useState(false)
    const [snackbar, showSnackbar] = useState({
        show: false,
        msg: "data added",
        type: "error",
    });

    const [openStausModal, setOpenStatusModal] = useState(false)
    const [status, setStatus] = useState('')
    const [deleteModal, setDeleteModal] = useState(false)

    async function getProfetionalData() {
        setLoader(true)
        try {
            let res = await getMatchingData('documents', 'userId', '==', id)
            setAllDocuments(res)
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (activeTab === '1') {
            getProfetionalData()
        }
    }, [activeTab])

    async function uploadDocuments(e) {
        e.preventDefault()
        setLoader(true)
        try {
            let res = await uploadFile(file)
            let payload = {
                fileName: file?.name,
                fileUrl: res,
                status: selectStatus,
                userId: id
            }
            await addDocument('documents', payload)
            showSnackbar({
                show: true,
                msg: "Document upload successfully",
                type: "success",
            })
        } catch (err) {
            console.log(err)
            showSnackbar({
                show: true,
                msg: "There are some problem please try again",
                type: "error",
            })
        } finally {
            await getProfetionalData()
            setLoader(false)
            setOpenAddModal(false)
            setFile(null)
            setSelectStatus('')
            setStatus('')
        }

    }

    function cardClick(data) {
        if(getPermissionAccess("Business", selector, false, 'View Business')){
            setOpenView(true)
            setViewData(data)
        }
    }


    async function updateStatus() {
        setLoader(true)
        try {
            await updateDocument('documents', docId, {status:status})
            showSnackbar({
                show: true,
                msg: "Document status changed",
                type: "success",
            })
        } catch (err) {
            console.log(err)
            showSnackbar({
                show: true,
                msg: "There are some problem please try again",
                type: "error",
            })
            return
        } finally {
            await getProfetionalData()
            setLoader(false)
            setOpenStatusModal(false)
            setDocId('')
            setStatus('')
        }

    }


    function setUpdateStatus(data) {
        setOpenStatusModal(true)
        setStatus(data)
    }

    async function deleteDocumentData() {
        setLoader(true)
        try {
            await deleteImage(viewData?.fileUrl)
            await deleteDocument('documents', viewData?.id)
            showSnackbar({
                show: true,
                msg: "Document deleted successfully",
                type: "success",
            })
        } catch (err) {
            console.log(err)
            showSnackbar({
                show: true,
                msg: "There are some problem please try again",
                type: "error",
            })
            return
        } finally {
            await getProfetionalData()
            setLoader(false)
            setViewData({})
            setDeleteModal(false)

        }

    }



    return (
        <>
            <DeleteModal open={deleteModal} setOpen={setDeleteModal} itemName='document' deleteFunction={deleteDocumentData} />
            <Snakbar data={snackbar} setData={showSnackbar} />
            <AddDocumentModal
                open={openAddModal}
                setOpen={setOpenAddModal}
                setSelectStatus={setSelectStatus}
                setFile={setFile}
                uploadDocuments={uploadDocuments}
                file={file}
                selectStatus={selectStatus}
            />
            <ViewFile
                data={viewData}
                open={openView}
                setOpen={setOpenView}
                setViewData={setViewData}
            />
            <StatusChangeModal
                open={openStausModal}
                setOpen={setOpenStatusModal}
                submit={updateStatus}
                status={status}
                setStatus={setStatus}
            />
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='fs-5 fw-600 text-secondary'>Documents</div>
                    {getPermissionAccess("Business", selector, false, 'Edit Business') ?
                    <button className='btn btn-sm btn-primary' onClick={() => setOpenAddModal(true)}>Add Document</button>:''}
                </div>
                <div className={`${style.docContainer} mt-4`}>
                    {allDocuments?.map((res, index) => <DocumentCard
                        key={index}
                        file={res}
                        cardClick={cardClick}
                        setUpdateStatus={setUpdateStatus}
                        setViewData={setViewData}
                        setDeleteModal={setDeleteModal}
                        setDocId={setDocId}
                    />)}
                </div>
            </div>
        </>
    )
}
