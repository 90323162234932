import React, { useEffect, useState } from 'react'
import Pagination from '../../Compunents/Pagination/Pagination';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import TableContainer from '../../Compunents/TableContainer/TableContainer';
import moment from 'moment';
import { changeDateFormate, paginateData } from '../../Utils/Utils';
import MapDialog from './MapDialog';

export default function Tab1({ userData, arr }) {
    const [page, setPage] = useState(0);
    const rowsPerPage = 5
    const [data, setData] = useState([])
    const [allData, setAllData] = useState([])
    const [noData, setNoData] = useState(false)
    const [locationModal, setLocationModal] = useState(false)
    const [location, setLocation] = useState(null)

    useEffect(() => {
        let newData = addMissingDates(arr)
        console.log(newData)
        setAllData(newData)
        let slicedData = paginateData(page, rowsPerPage, newData)
        console.log(slicedData)
        setData(slicedData)
    }, [page, rowsPerPage, arr])



    function addMissingDates(data) {
        data.sort((a, b) => a?.date?.seconds - b?.date?.seconds);

        const firstDate = moment(data[0]?.date?.seconds * 1000);
        const today = moment();

        const newData = [];
        let currentDate = moment(firstDate);

        while (currentDate.isSameOrBefore(today, 'day')) {
            const formattedDate = currentDate.format('YYYY-MM-DD');

            const existingEntry = data.find((entry) => {
                const entryDate = moment(entry.date.seconds * 1000).format('YYYY-MM-DD');
                return entryDate === formattedDate;
            });

            if (existingEntry) {
                newData.push(existingEntry);
            } else {
                newData.push({
                    date: { seconds: currentDate.unix(), nanoseconds: 0 },
                    punchIn: "--:--",
                    punchOut: "--:--",
                    totalHours: "0h 0m",
                    punchInLocation: 'NA',
                    punchOutLocation: "NA"
                });
            }

            currentDate.add(1, 'day');
        }

        return newData;
    }


    function getAmPm(timeString) {
        if (timeString === "--:--"  || timeString === null) {
            return '';
        }

        const [hours, minutes] = timeString.split(':').map(Number);

        if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
            return '';
        }

        return hours >= 12 ? 'PM' : 'AM';
    }

    function getLocation(data, type) {
        if (data?.punchInLocation !== 'NA') {
            let obj = data
            if (type == 'inLocation') {
              return obj?.punchInLocation? <span className='text-primary text-decoration-underline pointer' onClick={()=>{
                setLocation(obj?.punchInLocation)
                setLocationModal(true)
              }}>View Location</span>:''
            }
            else if (type == 'outLocation') {
              return obj?.punchOutLocation? <span className='text-primary text-decoration-underline pointer' onClick={()=>{
                setLocation(obj?.punchOutLocation)
                setLocationModal(true)
              }}>View Location</span>:''
            }
        } else {
          return 'NA'
        }
    
      }


    return (
        <>
      <MapDialog open={locationModal} toggle={() => {setLocationModal(false); setLocation(null)}} location={location} />

            <h2>{userData?.firstName} {userData?.lastName}</h2>
            <div className='mt-4'>
                <TableContainer>
                    <Table>
                        {noData ? <caption className='text-center'>No Data Found</caption> : ''}
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Punch In Time</TableCell>
                                <TableCell>Punch In Location</TableCell>
                                <TableCell>Punch Out Time</TableCell>
                                <TableCell>Punch In Location</TableCell>
                                <TableCell>Duration</TableCell>
                                <TableCell>Presence Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((res, index) => {

                                return (
                                    <TableRow hover key={index} >
                                        <TableCell className='pointer text-capitalize' >{moment(changeDateFormate(res?.date)).format('DD-MM-YYYY')}</TableCell>
                                        <TableCell className='text-capitalize'>{res?.punchIn} {getAmPm(res?.punchIn)}</TableCell>

                                        <TableCell>{getLocation(res,'inLocation')}</TableCell>
                                        <TableCell>{res?.punchOut} {getAmPm(res?.punchOut)}</TableCell>
                                        <TableCell>{getLocation(res,'outLocation')}</TableCell>
                                        <TableCell>{res?.totalHours}</TableCell>

                                        <TableCell className='text-capitalize' >
                                            {res?.punchOut !== '--:--' ? <div className='text-success'>Active</div> : <div className='text-danger'>Inactive</div>}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>

                    </Table>
                    <Pagination

                        allData={allData}
                        currentPage={page}
                        paginate={(d) => {
                            setPage(d);
                        }}
                        itemPerPage={rowsPerPage}
                    />

                </TableContainer>
            </div>
        </>
    )
}
