import React from 'react'
import style from './style.module.css'
import { BsThreeDotsVertical } from "react-icons/bs";
import DropDownMenu from '../../../Compunents/DropDownMenu/DropDownMenu';
import { MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { getPermissionAccess } from '../../../Utils/Utils';

export default function DocumentCard({ file, cardClick, setUpdateStatus, setViewData, setDeleteModal, setDocId }) {
    const selector = useSelector((e) => e?.permissionData?.permissions)


    function checkStatus(res) {
        switch (res) {
            case 'pending':
                return 'pendingStatus'
                break;
            case 'rejected':
                return 'rejectedStatus'
                break;
            case 'verified':
                return 'approvedStatus'
                break;


        }
    }


    return (
        <>
            <div className={`${style.DCard}`}>
                <div className={`w-100 pointer ${style.dUpper}`} onClick={() => cardClick(file)}>
                    <img src='/images/doc.png' className='w-100 h-100' alt='' />
                </div>
                <div className={`${style.dbottom}`}>
                    <div className='d-flex align-items-center pointer' onClick={() => cardClick(file)}>
                        <img src={`/images/doc.png`} className={`${style.filetype}`} alt='' />
                        <div>
                            <div className='text-black text-capitalize fw-600  f-14' title={file?.fileName}>{file?.fileName?.slice(0, 8)}{file?.fileName?.length > 8 ? '...' : ''}</div>
                            <span className={`${checkStatus(file?.status)} text-capitalize`}>{file?.status}</span>
                        </div>
                    </div>
                    {getPermissionAccess("Business", selector, false, 'Edit Business') ?
                        <DropDownMenu button={<BsThreeDotsVertical className='pointer' />}>
                            <MenuItem onClick={() => {
                                setUpdateStatus(file?.status)
                                setDocId(file?.id)
                            }}>Update status</MenuItem>
                            <MenuItem onClick={() => {
                                setDeleteModal(true)
                                setViewData(file)
                            }}>Delete</MenuItem>
                        </DropDownMenu> : ''}

                </div>
            </div>
        </>
    )
}
