import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";
// import { getMessaging}

const firebaseConfig = {
  apiKey: "AIzaSyAuqEBbxNMAC9pmkXtAqJxxDRl2ccodQ-E",
  authDomain: "linkease-39929.firebaseapp.com",
  projectId: "linkease-39929",
  storageBucket: "linkease-39929.appspot.com",
  messagingSenderId: "532906358529",
  appId: "1:532906358529:web:4d9d5b15bd7d29699f4d00"
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const messaging = getMessaging(app);