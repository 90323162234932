import { City, Country, State } from 'country-state-city';
import React, { useEffect, useState } from 'react'
import { FaRegEdit } from "react-icons/fa";
import { getDocumentData } from '../../../Firebase/CloudFirestore/GetData';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import MultiSelectDropdown from '../../../Compunents/MultiSelectdropDown/MultiSelectDropdown';
import { updateDocument } from '../../../Firebase/CloudFirestore/UpdateData';
import Snakbar from '../../../Compunents/Snackbar/Snakbar';
import moment from 'moment';
import { getPermissionAccess } from '../../../Utils/Utils';
import { useSelector } from 'react-redux';

export default function ProfileEdit({ setLoader, getUserData, activeTab }) {
    let { id } = useParams()
    const selector = useSelector((e) => e?.permissionData?.permissions)

    const { handleSubmit } = useForm();
    const countries = Country?.getAllCountries()
    const [isEdit, setIsEdit] = useState(true)
    const [formData, setFormData] = useState({})
    const [formData2, setFormData2] = useState({})
    const [cities, setCities] = useState([])
    const [skills, setSkills] = useState([])
    const [country, setCountry] = useState('')
    const [snackbar, showSnackbar] = useState({
        show: false,
        msg: "data added",
        type: "error",
    });

    const skillsArr = [
        "JavaScript",
        "Python",
        "Java",
        "C++",
        "HTML",
        "CSS",
        "React",
        "Angular",
        "Vue.js",
        "Node.js",
        "Express.js",
        "Django",
        "Flask",
        "Ruby on Rails",
        "Spring Boot",
        "Kotlin",
        "Swift",
        "PHP",
        "Laravel",
        "ASP.NET",
        "SQL",
        "MySQL",
        "PostgreSQL",
        "MongoDB",
        "Redis",
        "GraphQL",
        "RESTful APIs",
        "AWS",
        "Azure",
        "Google Cloud Platform",
        "Docker",
        "Kubernetes",
        "CI/CD",
        "Jenkins",
        "Git",
        "GitHub",
        "GitLab",
        "Bitbucket",
        "Agile Methodologies",
        "Scrum",
        "Kanban",
        "JIRA",
        "Confluence",
        "Trello",
        "Asana",
        "Slack",
        "Microsoft Office",
        "Excel",
        "PowerPoint",
        "Word",
        "Photoshop",
        "Illustrator",
        "Figma",
        "Sketch",
        "InDesign",
        "UI/UX Design",
        "Prototyping",
        "Wireframing",
        "Testing",
        "Unit Testing",
        "Integration Testing",
        "System Testing",
        "Manual Testing",
        "Automated Testing",
        "Selenium",
        "Cypress",
        "Jest",
        "Mocha",
        "Chai",
        "TestNG",
        "JUnit",
        "Behavior-Driven Development (BDD)",
        "Test-Driven Development (TDD)",
        "Business Analysis",
        "Project Management",
        "Product Management",
        "Marketing",
        "SEO",
        "Content Creation",
        "Copywriting",
        "Data Analysis",
        "Data Visualization",
        "Machine Learning",
        "Deep Learning",
        "Artificial Intelligence",
        "Natural Language Processing",
        "Computer Vision",
        "Big Data",
        "Hadoop",
        "Spark",
        "Tableau",
        "Power BI",
        "Blockchain",
        "Cryptocurrency",
        "Smart Contracts",
        "IoT",
        "Cybersecurity",
        "Penetration Testing",
        "Network Security",
        "Ethical Hacking",
        "DevOps",
        "SRE"
    ];




    async function getUserDataInner() {
        setLoader(true)
        try {
            let res = await getUserData()
            console.log(res)
            setFormData(res)
            setFormData2(res)
            setSkills(res?.skills)
            let country = countries?.find(item => item.name === res?.country)
            setCountry(JSON.stringify(country))
            getAllCities(JSON.stringify(country))
            // setCity(res?.city)

        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (activeTab == 0) {
            getUserDataInner()
        }
    }, [activeTab])


    function getAllCities(res) {
        setCountry(res)
        let obj = JSON.parse(res)
        let cities = City.getCitiesOfCountry(obj?.isoCode)
        setCities(cities)
    }

    async function submitData() {
        let cc = JSON.parse(country)
        let payload = {
            ...formData,
            country: cc?.name,
            state: '',
            skills: skills
        }
        setLoader(true)
        try {
            await updateDocument('users', id, payload)
            showSnackbar({
                show: true,
                msg: "User data updated successfully",
                type: "success",
            })
        } catch (err) {
            console.log(err)
            showSnackbar({
                show: true,
                msg: "There are some problem please try again",
                type: "error",
            })
            return
        } finally {
            await getUserData()
            await getUserDataInner()
            setLoader(false)
            setIsEdit(true)
        }

    }

    function handleInput(e) {
        let { value, name } = e.target
        if (value === ' ') {
            e.target.value = ''
        } else {
            setFormData(pre => {
                return {
                    ...pre,
                    [name]: value
                }
            })
        }
    }


    return (
        <>
            <Snakbar data={snackbar} setData={showSnackbar} />
            <div className=''>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='fs-5 fw-600 text-secondary'>Profile Details</div>
                    {getPermissionAccess("Job Seeker", selector, false, 'Edit Job Seeker') ?
                        <button className='btn btn-primary d-flex align-items-center' onClick={() => {
                            setIsEdit(!isEdit)
                            setFormData(formData2)
                        }} >{isEdit ? <><FaRegEdit className='me-1' /> Edit</> : 'Cancel'}</button> : ''}
                </div>
                <form className='w-100' onSubmit={handleSubmit(submitData)}>
                    <div className='row mt-4'>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>First Name</label>
                            <input className='form-control' placeholder='Enter first  name' required disabled={isEdit} name='firstName' value={formData?.firstName} onChange={handleInput} />
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Last Name</label>
                            <input className='form-control' placeholder='Enter last name' required disabled={isEdit} name='lastName' value={formData?.lastName} onChange={handleInput} />
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Date Of Birth</label>
                            <input type='date' className='form-control' placeholder='YYYY/MM/DD' required disabled={isEdit} name='dob' value={moment(formData?.dob, 'DD-MM-YYYY').format('yyyy-MM-DD')} onChange={(e) => {
                                setFormData(pre => {
                                    return {
                                        ...pre,
                                        [e.target.name]: moment(e.target.value, 'yyyy-MM-DD').format('DD-MM-YYYY')
                                    }
                                })
                            }} />
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Gender</label>
                            <select className='form-select' disabled={isEdit} required name='gender' value={formData?.gender} onChange={handleInput}>
                                <option value=''>Select Gender</option>
                                <option value='Male'>Male</option>
                                <option value='Female'>Female</option>
                            </select>
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Phone Number</label>
                            <input type='number' className='form-control' placeholder='Enter phone number' required disabled={isEdit} name='phoneNumber' value={formData?.phoneNumber} onChange={handleInput} onInput={(e) =>
                                (e.target.value = e.target.value.slice(0, 10))
                            } />
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Email Address</label>
                            <input type='email' className='form-control' placeholder='Enter email address' required disabled={isEdit} name='email' value={formData?.email} onChange={handleInput} />
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Industry</label>
                            <MultiSelectDropdown options={skillsArr} value={skills} setValue={setSkills} disabled={isEdit} />
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Nationality</label>
                            <select className='form-select' required disabled={isEdit} name='nationality' value={formData?.nationality} onChange={handleInput}>
                                <option value=''>Select Nationality</option>
                                {countries?.map((res, index) => <option key={index} value={res?.name}>{res?.name}</option>)}
                            </select>
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Country</label>
                            <select className='form-select' required disabled={isEdit} value={country} onChange={(e) => getAllCities(e.target.value)}>
                                <option value=''>Select Country</option>
                                {countries?.map((res, index) => <option key={index} value={JSON.stringify(res)}>{res?.name}</option>)}
                            </select>
                        </div>
                        {/* <div className='col-md-4 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>State</label>
                            <select className='form-select' required disabled={isEdit} value={state} onChange={(e) => getAllCities(e.target.value)}>
                                <option value=''>Select State</option>
                                {states?.map((res, index) => <option key={index} value={JSON.stringify(res)}>{res?.name}</option>)}
                            </select>
                        </div> */}
                        <div className='col-md-6 col-sm-12 col-12 mt-3'>
                            <label className='form-label mb-1'>City</label>
                            <select className='form-select' required disabled={isEdit} value={formData?.city} name='city' onChange={handleInput} >
                                <option value=''>Select City</option>
                                {cities?.map((res, index) => <option key={index} value={res?.name}>{res?.name}</option>)}
                            </select>
                        </div>

                        <div className='col-md-12 mt-3'>
                            <label className='form-label mb-1'>Address</label>
                            <textarea rows='4' className='form-control' placeholder='Enter full address' required disabled={isEdit} name='address' value={formData?.address} onChange={handleInput} />
                        </div>
                        {isEdit ? '' : <div className='d-flex justify-content-end gap-3 mt-4'>
                            {/* <button className='btn border'>Cancel</button> */}
                            <button className='btn btn-primary'>Submit</button>
                        </div>}
                    </div>
                </form>
            </div>
        </>
    )
}
