import React from 'react'

export default function AccessDeniedUI() {
    return (
        <>
            <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                <div className='border rounded p-3 mx-md-0 mx-sm-4 mx-2'>
                    <div className='text-center fs-5 fw-bold'>403 - Access Denied</div>
                    <div className='text-center text-gray mt-2'>You do not have the necessary permissions to view this page. Please contact the administrator.</div>
                </div>
            </div>
        </>
    )
}
