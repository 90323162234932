import React, { useEffect, useState } from 'react'
import Pagination from '../../Compunents/Pagination/Pagination'
import TableContainer from '../../Compunents/TableContainer/TableContainer'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import moment from 'moment'
import { changeDateFormate, getPermissionAccess, paginateData, roleData } from '../../Utils/Utils'
import CreateRole from './CreateRole'
import Loader from '../../Compunents/Loader/Loader'
import { addDocument } from '../../Firebase/CloudFirestore/SetData'
import { getCollectionData, getMatchingData } from '../../Firebase/CloudFirestore/GetData'
import { AiTwotoneDelete } from "react-icons/ai";
import { useNavigate } from 'react-router-dom'
import { deleteDocument } from '../../Firebase/CloudFirestore/DeleteData'
import Snakbar from '../../Compunents/Snackbar/Snakbar'
import DeleteModal from '../../Compunents/DeleteModal/DeleteModal'
import AccessDeniedUI from '../../Compunents/AccessDeniedUI/AccessDeniedUI'
import { useSelector } from 'react-redux'

export default function RoleList() {
    const navigate = useNavigate()
    const [search, setSearch] = useState('')
    const [openDialog, setopenDialog] = useState(false)
    const [loader, setLoader] = useState(false)
    const [page, setPage] = useState(0);
    const rowsPerPage = 5
  const selector = useSelector((e) => e?.permissionData?.permissions)

    const [data, setData] = useState([])
    const [allData, setAllData] = useState([])
    const [allData2, setAllData2] = useState([])
    const [noData, setNoData] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [snackbar, showSnackbar] = useState({
        show: false,
        msg: "data added",
        type: "error",
    });
    const [formData, setFormData] = useState({
        name: '',
    })
    const permissions = roleData



    function toggle() {
        setopenDialog(false)
        setFormData({
            name: '',
        })
    }

    function handleChange(e) {
        let { name, value } = e.target
        if (e.target.value == ' ') {
            e.target.value = ''
        } else {
            setFormData(pre => {
                return {
                    ...pre,
                    [name]: value
                }
            })
        }
    }

    async function createNewRole(e) {
        e.preventDefault()
        setLoader(true)
        try {
            let payload = {
                ...formData,
                createdAt: new Date(),
                permissions: permissions
            }
            let res = await addDocument('role', payload)
            setopenDialog(false)
            toggle()
            await getAllRoles()
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }


    async function getAllRoles() {
        setLoader(true)
        try {
            // let data = await getCollectionData('role')
            let [res, data] = await Promise.all([getCollectionData('dasboardUsers'), getMatchingData('role','name','!=','Super Admin')])
            let newData = data?.map(item => {
                let users = res?.filter(item2 => item2?.role == item?.id)
                return {
                    ...item,
                    users: users?.length
                }
            })



            if (newData?.length === 0) {
                setNoData(true)
            } else {
                setNoData(false)
            }
            setAllData(newData)
            setAllData2(newData)
            let slicedData = paginateData(page, rowsPerPage, newData)
            setData(slicedData)

        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }

    }

    useEffect(() => {
        getAllRoles()

    }, [])

    function searchData(e) {
        let value = e?.toLowerCase()
        setSearch(e)
        let arr = [...allData2]
        if (e == '') {
            setAllData(allData2)
            let slicedData = paginateData(page, rowsPerPage, allData2)
            setData(slicedData)
            if (slicedData.length == 0) {
                setNoData(true)
            } else {
                setNoData(false)
            }
        } else {
            let newArr = arr?.filter(res => res?.name?.toLowerCase()?.includes(value))
            setAllData(newArr)
            let slicedData = paginateData(page, rowsPerPage, newArr)
            setData(slicedData)
            if (slicedData.length == 0) {
                setNoData(true)
            } else {
                setNoData(false)
            }
        }

    }

    async function deleteRole() {
        try {
            setLoader(true)
            let res = await deleteDocument('role', deleteId)
        } catch (err) {
            console.log(err)
        } finally{
            setDeleteId('')
            setDeleteModal(false)
            await getAllRoles()
            setLoader(false)
        }
    }


    return (
        <>
            <DeleteModal
                open={deleteModal}
                setOpen={setDeleteModal}
                itemName={'Role'}
                deleteFunction={deleteRole}
            />
            <Loader open={loader} />
            <Snakbar data={snackbar} setData={showSnackbar} />
            <CreateRole
                open={openDialog}
                toggle={toggle}
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
                submitData={createNewRole}
            />
            {getPermissionAccess("Role Management", selector, true) ?<>
            <h2>Role Management</h2>
            <div className='row justify-content-md-between justify-content-sm-between justify-content-start mt-3'>
                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
                    <input type='search' className='form-control w-100' value={search} placeholder='Search role' onChange={(e) => {
                        if (e.target.value == ' ') {
                            e.target.value = ''
                        } else {
                            searchData(e.target.value)
                        }
                    }} />
                </div>
                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2'>
                    {getPermissionAccess("Role Management", selector, false, 'Create')?
                    <button className='btn btn-sm btn-primary ms-3' onClick={() => setopenDialog(true)}>Create New</button>:''}
                </div>
            </div>
            <div className='mt-4'>
                <TableContainer>
                    <Table>
                        {noData ? <caption className='text-center'>No Data Found</caption> : ''}
                        <TableHead>
                            <TableRow>
                                <TableCell>Role Name</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell>Assigned Users</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((res, index) => {
                                return (
                                    <TableRow hover key={index}>
                                        <TableCell>{res?.name}</TableCell>
                                        <TableCell>{moment(changeDateFormate(res?.createdAt)).format('DD MMM YYYY')}</TableCell>
                                        <TableCell>{res?.users}</TableCell>
                                        <TableCell>{getPermissionAccess("Role Management", selector, false, 'Update Access')?<button className='btn btn-sm btn-primary' onClick={() => navigate(`/roleManagement/access/${res?.id}`)}>Update Access</button>:''}</TableCell>
                                        <TableCell>{getPermissionAccess("Role Management", selector, false, 'Delete')?<AiTwotoneDelete className='pointer fs-5 text-primary' onClick={() => {
                                            if (res?.users == 0) {
                                                setDeleteId(res?.id)
                                                setDeleteModal(true)

                                            } else {
                                                showSnackbar({
                                                    show: true,
                                                    msg: `This role has assigned ${res?.users} users, so it cannot be deleted.`,
                                                    type: "error",
                                                })
                                            }
                                        }} />:''}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>

                    </Table>
                    <Pagination
                        allData={allData}
                        currentPage={page}
                        paginate={(d) => {
                            setPage(d);
                        }}
                        itemPerPage={rowsPerPage}
                    />

                </TableContainer>
            </div>
            </> : <>
       <AccessDeniedUI/>
      </>}
        </>
    )
}
