import { City, Country, State } from 'country-state-city';
import React, { useEffect, useState } from 'react'
import { FaRegEdit } from "react-icons/fa";
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import MultiSelectDropdown from '../../Compunents/MultiSelectdropDown/MultiSelectDropdown';
import { updateDocument } from '../../Firebase/CloudFirestore/UpdateData';
import Snakbar from '../../Compunents/Snackbar/Snakbar';
import moment from 'moment';
import { getCollectionData } from '../../Firebase/CloudFirestore/GetData';
import { useSelector } from 'react-redux';
import { getPermissionAccess } from '../../Utils/Utils';

export default function ProfileEdit({ setLoader, getUserData, activeTab }) {
    let { id } = useParams()
    const selector = useSelector((e) => e?.permissionData?.permissions)

    const { handleSubmit } = useForm();
    const [roles, setRoles] = useState([])
    const [isEdit, setIsEdit] = useState(true)
    const [formData, setFormData] = useState({})
    const [formData2, setFormData2] = useState({})
    const [snackbar, showSnackbar] = useState({
        show: false,
        msg: "data added",
        type: "error",
    });


    async function getAllRoles() {
        try {
            let data = await getCollectionData('role')
            console.log(data)
            setRoles(data)
        } catch (err) {
            console.log(err)
        }
    }



    async function getUserDataInner() {
        setLoader(true)
        try {
            let res = await getUserData()
            setFormData(res)
            setFormData2(res)
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (activeTab == 0) {
            getAllRoles()
            getUserDataInner()
        }
    }, [activeTab])

    async function submitData() {
        let payload = {
            ...formData
        }
        delete payload.id
        delete payload.createdAt
        delete payload.status

        setLoader(true)
        try {
            await updateDocument('dasboardUsers', id, payload)
            showSnackbar({
                show: true,
                msg: "User data updated successfully",
                type: "success",
            })
        } catch (err) {
            console.log(err)
            showSnackbar({
                show: true,
                msg: "There are some problem please try again",
                type: "error",
            })
            return
        } finally {
            await getUserData()
            await getUserDataInner()
            setLoader(false)
            setIsEdit(true)
        }

    }

    function handleInput(e) {
        let { value, name } = e.target
        if (value === ' ') {
            e.target.value = ''
        } else {
            setFormData(pre => {
                return {
                    ...pre,
                    [name]: value
                }
            })
        }
    }


    return (
        <>
            <Snakbar data={snackbar} setData={showSnackbar} />
            <div className=''>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='fs-5 fw-600 text-secondary'>Profile Details</div>
                    {getPermissionAccess("Dashboard Users", selector, false, 'Edit user')?
                    <button className='btn btn-primary d-flex align-items-center' onClick={() => {
                        setIsEdit(!isEdit)
                        setFormData(formData2)
                    }} >{isEdit ? <><FaRegEdit className='me-1' /> Edit</> : 'Cancel'}</button>:''}
                </div>
                <form className='w-100' onSubmit={handleSubmit(submitData)}>
                    <div className='row mt-4'>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Name <span className='text-danger'>*</span></label>
                            <input className='form-control' placeholder='Enter User name' required disabled={isEdit} name='name' value={formData?.name} onChange={handleInput} />
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Email <span className='text-danger'>*</span></label>
                            <input className='form-control' placeholder='' required disabled name='email' value={formData?.email} onChange={handleInput} />
                        </div>
                        <div className='col-md-6 col-sm-6 col-12 mt-3'>
                            <label className='form-label mb-1'>Phone Number <span className='text-danger'>*</span></label>
                            <input type='number' className='form-control' placeholder='Enter phone number' required disabled={isEdit} name='phoneNumber' value={formData?.phoneNumber} onChange={handleInput} onInput={(e) =>
                                (e.target.value = e.target.value.slice(0, 10))
                            } />
                        </div>
                        <div className='col-md-6 mt-3'>
                            <label className='form-label mb-1'>Role <span className='text-danger'>*</span></label>
                            <select className='form-select' disabled={isEdit} value={formData?.role} name='role' required onChange={handleInput}>
                                <option disabled selected value=''>Select Role</option>
                                {roles?.map((res, index) => <option key={index} value={res?.id}>{res?.name}</option>)}

                            </select>
                        </div>
                        {isEdit ? '' : <div className='d-flex justify-content-end gap-3 mt-4'>
                            {/* <button className='btn border'>Cancel</button> */}
                            <button className='btn btn-primary'>Submit</button>
                        </div>}
                    </div>
                </form>
            </div>
        </>
    )
}
