import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    permissions: []
}

export const PermissionDataSlice = createSlice({
    name: 'PermissionSlice',
    initialState,
    reducers: {
        updatePermissionData: (state, action) => {
            return ({ ...state, ...action.payload })
        }
    }
})
export const { updatePermissionData } = PermissionDataSlice.actions 