export const paginateData = (page, itemPerPage, allData) => {
  if (!Array.isArray(allData)) {
    return [];
  }
  const startIndex = page * itemPerPage
  const endIndex = page * itemPerPage + itemPerPage
  return allData.slice(startIndex, endIndex);
}


export function changeDateFormate(rowData) {
  return rowData?.seconds * 1000 + rowData?.nanoseconds / 1e6
}

export function getPermissionAccess(tab, permissions, module, subTab) {
  let data = [...permissions]
  let object = data?.find(res => res?.module == tab)
  if (module) {
    return object?.isAcess
  }
  else {
    if (subTab) {
      let subModueData = object?.sub_module
      let obj = subModueData?.find((res) => res?.module == subTab)
      return obj?.isAcess
    } else {
      return object || false
    }

  }

}


export const roleData = [
  {
    "module": "Notifications",
    "isAcess": false,
    "sub_module": [],
  },
  {
    "module": "User Management Whole",
    "isAcess": false,
    "sub_module": [],
  },
  {
    "module": "Business",
    "isAcess": false,
    "sub_module": [
      {
        "module": "View Business",
        "isAcess": false,
      },
      {
        "module": "Edit Business",
        "isAcess": false,
      },
      {
        "module": "Update status",
        "isAcess": false,
      },
    ],
  },
  {
    "module": "Job Seeker",
    "isAcess": false,
    "sub_module": [
      {
        "module": "View Job Seeker",
        "isAcess": false,
      },
      {
        "module": "Edit Job Seeker",
        "isAcess": false,
      },
      {
        "module": "Update status",
        "isAcess": false,
      },
    ],
  },
  {
    "module": "Dashboard Users",
    "isAcess": false,
    "sub_module": [
      {
        "module": "Create User",
        "isAcess": false,
      },
      {
        "module": "View User",
        "isAcess": false,
      },
      {
        "module": "Edit user",
        "isAcess": false,
      },
      {
        "module": "Update status",
        "isAcess": false,
      },
    ],
  },
  {
    "module": "Job Titles",
    "isAcess": false,
    "sub_module": [
      {
        "module": "Create",
        "isAcess": false,
      },
      {
        "module": "View",
        "isAcess": false,
      },
      {
        "module": "Edit",
        "isAcess": false,
      }
    ],
  },
  {
    "module": "Job Listing",
    "isAcess": false,
    "sub_module": [
      {
        "module": "View",
        "isAcess": false,
      },
      {
        "module": "Update status",
        "isAcess": false,
      }
    ]
  },
  {
    "module": "Listing Fee",
    "isAcess": false,
    "sub_module": []
  },
  {
    "module": "Payments",
    "isAcess": false,
    "sub_module": [
      {
        "module": "View",
        "isAcess": false,
      }
    ]
  },
  {
    "module": "Attendance Management",
    "isAcess": false,
    "sub_module": [
      {
        "module": "View",
        "isAcess": false,
      }
    ]
  },
  {
    "module": "System Management",
    "isAcess": false,
    "sub_module": [
      {
        "module": "View",
        "isAcess": false,
      }
    ]
  },
  {
    "module": "Role Management",
    "isAcess": false,
    "sub_module": [
      {
        "module": "Create",
        "isAcess": false,
      },
      {
        "module": "Update Access",
        "isAcess": false,
      },
      {
        "module": "Delete",
        "isAcess": false,
      },
    ]
  },

]